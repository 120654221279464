import { useState, useEffect } from "react"
import { throttle } from "utils/throttle"

export const useWindowScrollPosition = ({ throttleMs = 100 }: { throttleMs?: number } = {}) => {
  const [scroll, setScroll] = useState({ x: 0, y: 0 })
  // const [scroll, setScroll] = useState({
  //   x: typeof window === "undefined" ? 0 : window.pageXOffset,
  //   y: typeof window === "undefined" ? 0 : window.pageYOffset,
  // })

  useEffect(() => {
    const handle = throttle(() => {
      if (typeof window === "undefined") return
      setScroll({
        x: window.pageXOffset,
        y: window.pageYOffset,
      })
    }, throttleMs)

    window.addEventListener("scroll", handle)

    return () => {
      window.removeEventListener("scroll", handle)
    }
  }, [])

  return scroll
}
