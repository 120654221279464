import { useState } from "react"
import styled from "styled-components"
import { noop } from "utils/misc"

const RadioButtonItem = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`
const RadioButtonLabel = styled.span<{ $isSelected?: boolean; $isDisabled?: boolean }>`
  margin-left: 10px;
  font-size: var(--radio-button-label-font-size);
  color: ${(props) =>
    props.$isSelected ? "var(--radio-button-label-font-color-selected)" : "var(--radio-button-label-font-color)"};
  color: ${(props) =>
    props.$isDisabled
      ? "var(--radio-button-inactive-color)"
      : props.$isSelected
      ? "var(--radio-button-label-font-color-selected)"
      : "var(--radio-button-label-font-color)"};
`
const RadioButtonInput = styled.input`
  display: none;
`
const RadioButtonCircle = styled.div<{ $isSelected?: boolean; $isDisabled?: boolean; $isFocused?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: var(--radio-button-width);
  min-height: var(--radio-button-height);
  width: var(--radio-button-width);
  height: var(--radio-button-height);

  border-radius: 50%;

  border: solid 1px
    ${(props) =>
      props.$isDisabled
        ? "var(--radio-button-inactive-color)"
        : props.$isSelected
        ? "var(--radio-button-selected-border-color)"
        : "var(--radio-button-unselected-border-color)"};

  ${(props) => props.$isFocused && `box-shadow: 0px 0px 4px 4px var(--radio-button-transition-box-shadow-color);`}

  ${(props) =>
    props.$isSelected &&
    `
    opacity: 1;
  `}
`
const RadioButtonInnerCircle = styled.div<{ $isSelected?: boolean; $isDisabled?: boolean }>`
  width: var(--radio-button-inner-width);
  height: var(--radio-button-inner-height);
  border-radius: 50%;

  opacity: 0;
  transform: scale(0.01);
  transition: transform 300ms var(--quick-spin), opacity 300ms var(--juiced-up-ease);
  will-change: transform, opacity;

  background: ${(props) =>
    props.$isDisabled ? "var(--radio-button-inactive-color)" : "var(--radio-button-selected-border-color)"};

  ${(props) =>
    props.$isSelected &&
    `
    opacity: 1;
    transform: scale(1);
  `}
`

interface IRadioButton {
  name: string
  selected?: any
  isDisabled?: boolean
  item: { label: string; value: any }
  handleSelect: (name: string, label: string, value: any) => void
  className?: string
}
export default function RadioButton({ name, selected, item, handleSelect, isDisabled, className = "" }: IRadioButton) {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <RadioButtonItem className={className}>
      <RadioButtonInput
        type="radio"
        name={name}
        value={item.value}
        checked={selected === item.value}
        aria-checked={selected === item.value}
        onChange={() => (isDisabled ? noop : handleSelect(name, item.label, item.value))}
        role="radio"
      />

      <RadioButtonCircle
        $isFocused={isFocused}
        onMouseDown={() => (isDisabled ? noop : setIsFocused(true))}
        onMouseUp={() => (isDisabled ? noop : setIsFocused(false))}
        $isDisabled={isDisabled}
        $isSelected={selected === item.value}
      >
        <RadioButtonInnerCircle $isDisabled={isDisabled} $isSelected={selected === item.value} />
      </RadioButtonCircle>

      <RadioButtonLabel $isDisabled={isDisabled} $isSelected={selected === item.value}>
        {item.label}
      </RadioButtonLabel>
    </RadioButtonItem>
  )
}
