import { css } from "styled-components"

export const customMaxMediaQuery = (maxWidth: number): string => `@media (max-width: ${maxWidth}px)`

// export interface MediaQuerySizes {
//   xl?: string
//   desktop?: string
//   large?: string
//   tablet?: string
//   medium?: string
//   small?: string
//   xs?: string
//   mobile?: string
//   mobileOnly?: string
//   stupidSmall?: string
//   mini?: string
// }

// const sizes: MediaQuerySizes = {
//   xl: "1550px",
//   desktop: "1300px",
//   larger: "1200px",
//   large: "1100px",
//   tablet: "980px",
//   medium: "700px",
//   small: "550px",
//   xs: "400px",
//   mobile: "325px",
//   // stupidSmall: "150px",
//   mini: "150px",
// }

const sizes = {
  xl: "1550px",
  desktop: "1250px",
  larger: "1175px",
  large: "1100px",
  tablet: "980px",
  mediumPlus: "850px",
  medium: "700px",
  small: "550px",
  xs: "400px",
  mobile: "325px",
  // stupidSmall: "150px",
  mini: "150px",
}

const otherSizes = {
  mobileOnly: "550px",
  stupidSmall: "325px",
}

const allSizes = { ...sizes, ...otherSizes }

type MediaQuerySizes = keyof typeof allSizes // & keyof typeof otherSizes

// type MediaQueryFunc = (literals: TemplateStringsArray, ...placeholders: any[]) => any

const mediaTemplates = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media only screen and (min-width: ${sizes[label]}) {
        ${css(literals, ...placeholders)}
      }
    `.join("")
  return acc
}, {} as Record<MediaQuerySizes, (l: TemplateStringsArray, ...p: any[]) => any>)

// const mediaTemplates = Object.keys(sizes).reduce((acc, label) => {
//   acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
//     css`
//       @media only screen and (min-width: ${sizes[label]}) {
//         ${css(literals, ...placeholders)}
//       }
//     `.join("")
//   return acc
// }, {} as Record<keyof typeof sizes, (l: TemplateStringsArray, ...p: any[]) => any>)

mediaTemplates.mobileOnly = (literals: TemplateStringsArray, ...placeholders: any[]) => css`
  @media only screen and (max-width: 550px) {
    ${css(literals, ...placeholders)}
  }
`
mediaTemplates.stupidSmall = (literals: TemplateStringsArray, ...placeholders: any[]) => css`
  @media only screen and (max-width: 325px) {
    ${css(literals, ...placeholders)}
  }
`

export default mediaTemplates
