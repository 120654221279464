import { RadioButton } from "components"
import styled from "styled-components"
// import { isDefined } from "utils/parseUtils"

const RadioButtonGroupWrapper = styled.div``

type RadioButtonItem = { label: string; value: any }
interface RadioButtonGroupProps<T extends RadioButtonItem> {
  items: T[]
  name: string
  selected?: any
  isDisabled?: any
  onSelect: (name: string, label: string, val: any) => void
  className?: string
}
export default function RadioButtonGroup<T extends RadioButtonItem>({
  items,
  name,
  onSelect,
  selected,
  isDisabled,
  className = "",
}: RadioButtonGroupProps<T>) {
  const handleSelect = (name: string, label: string, val: any) => {
    if (val === selected) {
      // console.log("nullify")
      return onSelect(name, label, null)
    }

    onSelect(name, label, val)
  }

  return (
    <RadioButtonGroupWrapper className={className} aria-labelledby={name}>
      {items.map((item) => (
        <RadioButton
          key={item.value}
          isDisabled={isDisabled}
          item={item}
          selected={selected}
          name={name}
          handleSelect={handleSelect}
        />
      ))}
    </RadioButtonGroupWrapper>
  )
}
