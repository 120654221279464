import styled from "styled-components"

const StyledAccentDot = styled.div`
  height: 40px;
  width: 40px;
  background: var(--color-accent);
  border-radius: 50%;
`

export default function AccentDot({ className = "" }) {
  return <StyledAccentDot className={className} />
}
