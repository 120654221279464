import { useAuth } from "hooks/auth/useAuth"

export const useAuthedUser = () => {
  const { session, isSessionLoading } = useAuth({ isAuthPage: false })

  const user = session?.userInfo?.user
  const nestedUserInfo = session?.userInfo?.user?.userInfo?.user

  const authedUser = nestedUserInfo || user

  return [authedUser, isSessionLoading]
}
