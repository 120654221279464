import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Home: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <rect
        x="20"
        y="17"
        width="8"
        height="8"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
      <polyline
        points="2 21 24 3 46 21"
        fill={fill}
        stroke={stroke}
        strokeLinecap={"butt"}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
      <polyline
        points="19 45 19 33 29 33 29 45"
        fill={fill}
        stroke={stroke}
        strokeLinecap={"butt"}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
      <polyline
        points="7 24 7 45 41 45 41 24"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
      <line
        x1="9"
        y1="15.273"
        x2="9"
        y2="7"
        fill={fill}
        stroke={stroke}
        strokeLinecap={"butt"}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
    </IconWrapper>
  )
}

export default Home
