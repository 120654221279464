import styled from "styled-components"

export type FlexDirection = "row" | "column"
export type FlexWrap = "wrap" | "no-wrap"
export type JustifyContent = "flex-start" | "flex-end" | "space-around" | "space-between" | "center"
export type AlignItems = "flex-start" | "flex-end" | "space-around" | "space-between" | "center"

const StyledRow = styled.div<{
  $flexGrow: number
  $flexWrap: FlexWrap
  $flexDirection: FlexDirection
  $justifyContent: JustifyContent
  $alignItems: AlignItems
}>`
  display: flex;

  ${(props) => `
    flex: ${props.$flexGrow};
    flex-direction: ${props.$flexDirection};
    flex-wrap: ${props.$flexWrap};
    justify-content: ${props.$justifyContent};
    align-items: ${props.$alignItems};
  `}
`

interface IRow {
  flexGrow?: number
  justifyContent?: JustifyContent
  flexDirection?: FlexDirection
  flexWrap?: FlexWrap
  alignItems?: AlignItems
  className?: string
}
const Row: React.FC<IRow> = ({
  flexGrow = 1,
  flexDirection = "row",
  justifyContent = "flex-start",
  alignItems = "flex-start",
  flexWrap = "no-wrap",
  className = "",
  children,
}) => {
  return (
    <StyledRow
      $flexGrow={flexGrow}
      $flexDirection={flexDirection}
      $flexWrap={flexWrap}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      className={className}
    >
      <>{children}</>
    </StyledRow>
  )
}

export default Row
