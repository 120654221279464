import styled from "styled-components"
import { IIcon } from "types/ui"
import { noop } from "utils/misc"

const StyledSVG = styled.svg``

const Search: React.FC<IIcon> = ({
  size = 24,
  fill = "none",
  strokeSemiCircle,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  className = "",
  onClick = noop,
}) => {
  return (
    <StyledSVG
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      fill="none"
      onClick={onClick}
    >
      <line
        x1="22"
        y1="22"
        x2="18"
        y2="18"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
      <circle
        cx="10"
        cy="10"
        r="8"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
      <path
        d="M6,10a4,4,0,0,1,4-4"
        fill={fill}
        stroke={strokeSemiCircle || stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit={strokeMiterlimit}
      />
    </StyledSVG>
  )
}

export default Search
