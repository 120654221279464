import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Exhibition: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  fillRect,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <path
        d="M4.065,31.983a14.456,14.456,0,0,0,20,0A14.453,14.453,0,0,0,44,32.043"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></path>
      <line
        x1="24"
        y1="44"
        x2="24"
        y2="30"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <rect
        x="10"
        y="4"
        width="28"
        height="20"
        fill={fillRect || fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></rect>
      <rect
        x="15"
        y="9"
        width="18"
        height="10"
        fill={fillRect ? "var(--pure-white)" : fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></rect>
      <line
        x1="4"
        y1="44"
        x2="4"
        y2="30"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="44"
        y1="44"
        x2="44"
        y2="30"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="22"
        y1="44"
        x2="26"
        y2="44"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="2"
        y1="44"
        x2="6"
        y2="44"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="42"
        y1="44"
        x2="46"
        y2="44"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
    </IconWrapper>
  )
}

export default Exhibition
