import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Comments: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  fillFirstComment,
  fillSecondComment,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <path
          d="M38,11h4a4,4,0,0,1,4,4V32a4,4,0,0,1-4,4H38v8L26,36H22"
          fill={fillSecondComment || fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M34,3H6A4,4,0,0,0,2,7V24a4,4,0,0,0,4,4h4V38L24,28H34a4,4,0,0,0,4-4V7A4,4,0,0,0,34,3Z"
          fill={fillFirstComment || fillSecondComment ? "var(--pure-white)" : fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></path>
      </g>
    </IconWrapper>
  )
}

export default Comments
