// import { useRouter } from "next/router"
import { useNavbar } from "hooks/ui/useNavbar"
import styled from "styled-components"

const NavbarLogoContainer = styled.div`
  height: var(--navbar-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledNavbarLogo = styled.img`
  height: 75px;
  width: 215px;
  cursor: pointer;
`

const NavbarLogo: React.FC = () => {
  const { navigateInternal } = useNavbar()
  // const router = useRouter()

  return (
    <NavbarLogoContainer>
      {/* <StyledNavbarLogo src={"/assets/logos/logo_260x90.svg"} alt="velup logo" /> */}
      <StyledNavbarLogo src={"/assets/logos/logo_215x75.svg"} alt="velup logo" onClick={() => navigateInternal("/")} />
      {/* <StyledNavbarLogo src={"/assets/logos/logo_150x50.svg"} alt="velup logo" /> */}
    </NavbarLogoContainer>
  )
}

export default NavbarLogo
