// import { useRouter } from "next/router"
import { Fragment } from "react"
import { Card, IconClose, IconDanger, IconInfo, IconSuccess, IconWarning, Spacer } from "components"
import styled from "styled-components"

import { ToastID, Toast as ToastShape, ToastType } from "types/ui"

const ToastBackground = styled((props) => <Card {...props} />)<{ $toastType?: ToastType }>`
  width: calc(var(--toast-width) + var(--toast-colored-border-size));
  border-radius: var(--toast-border-radius);
  background: var(--toast-color-${(props) => props.$toastType});

  padding-left: var(--toast-colored-border-size);
  margin-top: 1rem;  

  display: flex;
  flex-direction: row;
  z-index: var(--z-index-toast);

  transition: transform: 0.4s ease-in-out;
  animation: toast-in-right .4s;  
`
const ToastWrapper = styled((props) => <Card {...props} />)<{ $toastType?: ToastType }>`
  width: var(--toast-width);
  flex: 1;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--background-color-lightest-blue);
  border-radius: 2px;
`
const ToastIcon = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & svg {
    transform: scale(0.5);
  }
`
const ToastBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`
const ToastTitle = styled.h3`
  margin: 10px 0;
  font-family: var(--font-primary);
  font-weight: 400;
  color: var(--toast-title-color);
`
const ToastContents = styled.div`
  margin-bottom: 10px;
  color: var(--toast-body-color);
`
const ToastActions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
`
const ToastActionButton = styled.button<{ $inverted?: boolean; $toastType: ToastType }>`
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  font-family var(--font-primary);
  font-size: 16px;
  border-radius: var(--border-radius-sm);

  ${(props) => `
    --bg-color: var(--toast-color-${props.$toastType}-light);
    --text-color: var(--toast-button-text-color-${props.$toastType});

    background: ${props.$inverted ? "var(--pure-white)" : "var(--bg-color)"};
    color: var(--text-color);
  `}
`
const ToastClose = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
const StyledIconClose = styled((props) => <IconClose {...props} />)`
  transform: translateY(-8px) scale(0.5);
  cursor: pointer;
`

const renderIcon = (toast: ToastShape) => {
  return {
    info: <IconInfo stroke="var(--toast-color-info)" strokeWidth={3} />,
    danger: <IconDanger stroke="var(--toast-color-danger)" strokeWidth={4} />,
    warning: <IconWarning stroke="var(--toast-color-warning)" strokeWidth={4} />,
    success: <IconSuccess stroke="var(--toast-color-success)" strokeWidth={4} />,
  }[toast.type || "info"]
}

interface IToast {
  toast: ToastShape
  removeToast: ({ id }: { id: ToastID }) => void
  navigate: (path: string) => void
}

const Toast: React.FC<IToast> = ({ toast, removeToast, navigate }) => {
  const hasActions = Boolean(toast.primaryAction) || Boolean(toast.secondaryAction)

  return (
    <ToastBackground $toastType={toast.type} elevation={"high"}>
      <ToastWrapper $toastType={toast.type}>
        <ToastIcon>{renderIcon(toast)}</ToastIcon>
        <ToastBody>
          <ToastTitle>{toast.title}</ToastTitle>
          <ToastContents>{toast.contents}</ToastContents>

          {hasActions ? (
            <ToastActions>
              {/* {toast.primaryAction && (
                <ToastActionButton
                  onClick={() => console.log(toast.primaryAction.value)}
                  $inverted={false}
                  $toastType={toast.type}
                  key={toast.primaryAction.value}
                >
                  {toast.primaryAction.label}
                </ToastActionButton>                
              )}
              {toast.secondaryAction && (
                <ToastActionButton
                  onClick={() => console.log(toast.secondaryAction.value)}
                  $inverted={true}
                  $toastType={toast.type}
                  key={toast.secondaryAction.value}
                >
                  {toast.secondaryAction.label}
                </ToastActionButton>                
              )}               */}
              {[toast.primaryAction, toast.secondaryAction].map((action, i) => (
                <Fragment key={action.value}>
                  <ToastActionButton
                    onClick={() => console.log(action.value)}
                    $inverted={i % 2 === 1}
                    $toastType={toast.type}
                  >
                    {action.label}
                  </ToastActionButton>
                  <Spacer size="md" />
                </Fragment>
              ))}
            </ToastActions>
          ) : null}
          {/* {toast.link ? <StyledLinkIcon stroke="white" onClick={() => navigate(toast.link)} /> : null} */}
        </ToastBody>

        <ToastClose>
          <StyledIconClose onClick={() => removeToast({ id: toast.id })} />
        </ToastClose>
      </ToastWrapper>
    </ToastBackground>
  )
}

export default Toast

// function Toast({ toast, removeToast, navigate }: IToast) {
//   const renderIcon = () => {
//     return {
//       info: <IconInfo stroke="white" />,
//       danger: <IconDanger stroke="white" />,
//       warning: <IconWarning stroke="white" />,
//       success: <IconCheckAll stroke="white" />,
//     }[toast.type || "info"]
//   }

//   return (
//     <ToastWrapper toastType={toast.type} elevation={2}>
//       <div className="icon">{renderIcon()}</div>
//       <div className="toast-body">
//         <h3>{toast.title}</h3>
//         <div className="contents">{toast.contents}</div>
//         {toast.link ? <StyledLinkIcon stroke="white" onClick={() => navigate(toast.link)} /> : null}
//       </div>
//       <div className="actions">
//         <CloseButton type="plain" onClick={() => removeToast({ id: toast.id })}>{`X`}</CloseButton>
//       </div>
//     </ToastWrapper>
//   )
// }

// export default function ToastList() {
//   const router = useRouter()
//   const dispatch = useDispatch()
//   const toastList = useTypedSelector((state) => state.ui.toastList, shallowEqual)

//   if (!toastList?.length) return null

//   const removeToast = (id: ToastID) => dispatch(uiActions.removeUiToast({ id }))
//   const navigate = (path: string) => router.push(path)

//   return (
//     <Portal>
//       <ToastListWrapper>
//         {toastList.map((toast: ToastType) => (
//           <Toast key={toast.id} toast={toast} removeToast={() => removeToast(toast.id)} navigate={navigate} />
//         ))}
//       </ToastListWrapper>
//     </Portal>
//   )
// }

// interface IToast {
//   toast: ToastType
//   removeToast: ({ id }: { id: ToastID }) => void
//   navigate: (path: string) => void
// }
// function Toast({ toast, removeToast, navigate }: IToast) {
//   const renderIcon = () => {
//     return {
//       info: <IconInfo stroke="white" />,
//       danger: <IconDanger stroke="white" />,
//       warning: <IconWarning stroke="white" />,
//       success: <IconCheckAll stroke="white" />,
//     }[toast.type || "info"]
//   }

//   return (
//     <ToastWrapper toastType={toast.type} elevation={2}>
//       <div className="icon">{renderIcon()}</div>
//       <div className="toast-body">
//         <h3>{toast.title}</h3>
//         <div className="contents">{toast.contents}</div>
//         {toast.link ? <StyledLinkIcon stroke="white" onClick={() => navigate(toast.link)} /> : null}
//       </div>
//       <div className="actions">
//         <CloseButton type="plain" onClick={() => removeToast({ id: toast.id })}>{`X`}</CloseButton>
//       </div>
//     </ToastWrapper>
//   )
// }
