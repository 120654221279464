import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const UserCircle: React.FC<IIcon> = ({
  size = 64,
  fill = "none",
  stroke = "#605DEC",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeLinejoin = "miter",
  strokeMiterlimit = 10,
  className = "",
  ...props
}) => (
  <IconWrapper size={size} className={className} {...props}>
    <g strokeWidth={strokeWidth} transform="translate(0, 0)">
      <path
        d="M32,42L32,42c-6.075,0-11-4.925-11-11v-3c0-6.075,4.925-11,11-11h0c6.075,0,11,4.925,11,11v3C43,37.075,38.075,42,32,42z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></path>
      <path
        d="M14.529,56.391C16.973,51.421,22.088,48,28,48h8c5.906,0,11.024,3.43,13.471,8.391"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={"butt"}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></path>
      <circle
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
        cx="32"
        cy="32"
        r="30"
      ></circle>
    </g>
  </IconWrapper>
)

export default UserCircle
