import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const School: React.FC<IIcon> = ({
  size = 48,
  fillLight = "#E6E6E6",
  fillDark = "#444",
  fillLightGrey = "#BADEFC",
  fillGrey = "#B3B3B3",
  fillDarkGrey = "#5B5B5B",
  strokeLight = "#E6E6E6",
  strokeDark = "#444",
  strokeLightGrey = "#BADEFC",
  strokeGrey = "#B3B3B3",
  strokeDarkGrey = "#5B5B5B",
  strokeWidth = 0.5,
  className = "",
  ...props
}) => (
  <IconWrapper size={size} {...props}>
    <g strokeWidth={strokeWidth} transform="translate(0, 0)">
      <path
        stroke={strokeDarkGrey}
        fill={fillDarkGrey}
        d="M24,15c-0.553,0-1-0.448-1-1V2c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v12C25,14.552,24.553,15,24,15z"
      ></path>
      <path fill="#E86C60" stroke="#E86C60" d="M34,11h-9V3h9c0.552,0,1,0.448,1,1v6C35,10.552,34.552,11,34,11z"></path>
      <path
        stroke={strokeDark}
        fill={fillDark}
        d="M44,29H5H4c-0.553,0-1,0.448-1,1v16c0,0.552,0.447,1,1,1h40c0.553,0,1-0.448,1-1V30
	C45,29.448,44.553,29,44,29z"
      ></path>
      <path
        stroke={strokeGrey}
        fill={fillGrey}
        d="M34.625,21.219l-10-8c-0.365-0.292-0.885-0.292-1.25,0l-10,8C13.138,21.409,13,21.696,13,22v7h22v-7
	C35,21.696,34.862,21.409,34.625,21.219z"
      ></path>
      <path
        stroke="#FFFFFF"
        fill="#FFFFFF"
        d="M25,26h-2c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v2C26,25.552,25.552,26,25,26z
	"
      ></path>
      <path
        stroke={strokeLightGrey}
        fill={fillLightGrey}
        d="M10,40H8c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v2C11,39.552,10.552,40,10,40z"
      ></path>
      <path
        stroke={strokeLightGrey}
        fill={fillLightGrey}
        d="M17,40h-2c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v2C18,39.552,17.552,40,17,40z
	"
      ></path>
      <path
        stroke={strokeLightGrey}
        fill={fillLightGrey}
        d="M33,40h-2c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v2C34,39.552,33.552,40,33,40z
	"
      ></path>
      <path
        stroke={strokeLightGrey}
        fill={fillLightGrey}
        d="M40,40h-2c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v2C41,39.552,40.552,40,40,40z
	"
      ></path>
      <path
        fill={fillLight}
        stroke={strokeLight}
        d="M25,41h-2c-0.552,0-1,0.448-1,1v5h4v-5C26,41.448,25.552,41,25,41z"
      ></path>
    </g>
  </IconWrapper>
)

export default School
