import styled from "styled-components"

const StyledSvg = styled.svg``

interface IBellSimple {
  stroke?: string
  className?: string
  strokeWidth?: number
  strokeLinecap?: "round" | "butt" | "square" | "inherit"
  strokeLinejoin?: "round" | "inherit" | "miter" | "bevel"
}
export default function BellSimple({
  stroke = "#6E7491",
  className = "",
  strokeWidth = 2,
  strokeLinecap = "round",
  strokeLinejoin = "round",
}: IBellSimple) {
  return (
    <StyledSvg width="48" height="48" viewBox="0 0 48 48" fill="none" className={className}>
      <path d="M18 42H30" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.5381 19.4999C10.5356 17.721 10.8852 15.9591 11.5669 14.3159C12.2485 12.6726 13.2486 11.1806 14.5095 9.92566C15.7705 8.67076 17.2674 7.67784 18.9139 7.00414C20.5603 6.33044 22.3239 5.98928 24.1028 6.00032C31.5259 6.05551 37.4634 12.2256 37.4634 19.6694V20.9999C37.4634 27.7154 38.8684 31.6122 40.1059 33.7421C40.2392 33.9697 40.3101 34.2284 40.3115 34.4921C40.3129 34.7559 40.2448 35.0153 40.1139 35.2443C39.983 35.4733 39.7941 35.6637 39.5662 35.7964C39.3382 35.9291 39.0793 35.9993 38.8156 35.9999H9.18444C8.92067 35.9993 8.66174 35.929 8.43378 35.7963C8.20581 35.6636 8.01687 35.4732 7.88602 35.2441C7.75517 35.0151 7.68704 34.7556 7.6885 34.4918C7.68996 34.2281 7.76095 33.9693 7.89432 33.7418C9.13244 31.6118 10.5381 27.715 10.5381 20.9999L10.5381 19.4999Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
      />
    </StyledSvg>
  )
}
