import { useState } from "react"
import styled from "styled-components"
import { noop } from "utils/misc"

const StyledLabel = styled.label<{ $isChecked?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: var(--font-color-primary);
`
const StyledInput = styled.input`
  display: none;
`
const StyledCheckbox = styled.span<{ $isFocused?: boolean; $isChecked: boolean; $isDisabled: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--checkbox-border-radius);
  width: var(--checkbox-width);
  max-width: var(--checkbox-width);
  height: var(--checkbox-height);

  ${(props) => props.$isFocused && `box-shadow: 0px 0px 4px 4px var(--checkbox-transition-box-shadow-color);`}

  border: solid 1px
    ${(props) =>
    props.$isDisabled
      ? "var(--checkbox-inactive-color)"
      : props.$isChecked
      ? "var(--checkbox-selected-border-color)"
      : "var(--checkbox-unselected-border-color)"};
`
const CheckboxInner = styled.span<{ $isChecked: boolean; $isDisabled: boolean }>`
  border-radius: var(--checkbox-inner-border-radius);
  min-width: var(--checkbox-inner-width);
  width: var(--checkbox-inner-width);
  max-width: var(--checkbox-inner-width);
  height: var(--checkbox-inner-height);
  max-height: var(--checkbox-inner-height);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: var(--checkbox-inner-width);

  opacity: 0;
  transform: scale(0.001);
  transition: transform 300ms var(--quick-spin), opacity 300ms var(--juiced-up-ease);
  will-change: transform, opacity;

  background: ${(props) =>
    props.$isDisabled ? "var(--checkbox-inactive-color)" : "var(--checkbox-selected-border-color)"};

  ${(props) =>
    props.$isChecked &&
    `
    opacity: 1;
    transform: scale(1);

  `}
`
const LeftSpaceSpan = styled.span<{ $isChecked?: boolean; $isDisabled?: boolean }>`
  margin-left: 10px;
  font-size: var(--checkbox-label-font-size);
  color: ${(props) => (props.$isDisabled ? "var(--checkbox-inactive-color)" : "var(--checkbox-label-font-color)")};
`

interface ICheckbox {
  isChecked?: boolean
  toggleChecked: () => void
  label?: React.ReactNode
  isDisabled?: boolean
  className?: string
}
const Checkbox: React.FC<ICheckbox> = ({ isChecked, toggleChecked, label, isDisabled, className = "" }) => {
  const [focused, setFocused] = useState(false)

  return (
    <StyledLabel className={className}>
      <StyledInput
        checked={isChecked}
        type="checkbox"
        aria-checked={isChecked}
        onChange={() => {}}
        // onChange={() => (isDisabled ? noop : toggleChecked())}
      />
      <StyledCheckbox
        $isFocused={focused}
        $isChecked={isChecked}
        $isDisabled={isDisabled}
        onMouseDown={() => (isDisabled ? noop : setFocused(true))}
        onMouseUp={() => (isDisabled ? noop : setFocused(false))}
        onClick={() => (isDisabled ? noop : toggleChecked())}
      >
        <CheckboxInner $isChecked={isChecked} $isDisabled={isDisabled} />
      </StyledCheckbox>
      {label && (
        <LeftSpaceSpan $isDisabled={isDisabled} $isChecked={isChecked}>
          {label}
        </LeftSpaceSpan>
      )}
    </StyledLabel>
  )
}

export default Checkbox
