import { useEffect, useMemo } from "react"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"
import { useSession, signOut, signIn } from "next-auth/client"
import { Actions as authActions } from "models/auth"
import { LoginCredentials, SignupCredentials } from "types/auth"

export const useAuth = ({ isAuthPage = false, nextUrl = null }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [session, isSessionLoading] = useSession()

  // if a user is logged in, never show them this page,
  // go straight to app
  useEffect(() => {
    if (isAuthPage) {
      if (session) {
        if (nextUrl) {
          router.push(nextUrl)
        } else {
          router.push("/profile/")
        }
      }
    }
  }, [session, router, isSessionLoading])

  const handlers = useMemo(
    () => ({
      handleOnUserLogin: async (loginCreds: LoginCredentials) => {
        try {
          // console.log(`logging in with`)
          // console.log({ loginCreds })
          const res = await signIn("velup-auth", {
            // callbackUrl: `http://localhost:3000/login/`,
            redirect: false,
            email: loginCreds.email,
            password: loginCreds.password,
          })
          // console.log({ res })
          return res
        } catch (err) {
          // console.log({ err })
          // console.log(err)
          // handle other errors
          // send out toast message
        }
      },
      handleOnUserSignup: async (signupCreds: SignupCredentials) => {
        // console.log(`signing up with`)
        // console.log({ signupCreds })

        try {
          const res = await signIn("velup-auth", {
            // callbackUrl: `http://localhost:3000/login/`,
            redirect: false,
            email: signupCreds.email,
            username: signupCreds.username,
            password: signupCreds.password,
          })
          // console.log({ res })
          return res
        } catch (err) {
          // console.log({ err })
          // handle other errors
          // send out toast message
        }
      },
      signOut: () => {
        // console.log("singing out")
        dispatch(authActions.signUserOut())
        signOut()
      },
    }),
    []
  )

  return { session, isSessionLoading, ...handlers }
}
