import Image from "next/image"
import styled from "styled-components"

const StyledImage = styled(Image)<{ $pointer?: boolean }>`
  ${(props) => props.$pointer && `cursor: pointer;`}
`
const BlurredImage = styled(StyledImage)``

/***
 * Possible layouts: "fill", "responsive", "intrinsic", "fixed", etc
 */
export default function NextImage({
  src,
  alt = "avatar",
  layout = "fill",
  // height = 300,
  // width = 300,
  pointer,
  ...props
}) {
  return (
    <>
      <BlurredImage
        // className="blur"
        src={require(`images/${src}?lqip`)}
        alt={alt}
        // @ts-ignore
        layout={layout}
        $pointer={pointer}
        // $rounded={rounded}
        // $circular={circular}
        {...props}
      />
      <StyledImage
        // src={src}
        src={require(`images/${src}?webp`)}
        // @ts-ignore
        layout={layout}
        // objectFit="cover"
        quality={100}
        alt={alt}
        // width={width}
        // height={height}
        $pointer={pointer}
        {...props}
        // something
      />
    </>
  )
}
