interface IUseCreateStars {
  numStars?: number
  max?: number
  epsilon?: number
}
export const useCreateStars = ({
  numStars,
  max,
  epsilon = 0.2,
}: IUseCreateStars): Array<{ pos: string; star: "full" | "half" | "empty" }> => {
  return new Array(max).fill(0).map((_, i) => {
    const starsLeft = numStars - i
    if (starsLeft >= 1) return { pos: String(i + 1), star: "full" }
    // this part makes sure that anything 4.8 and above gets rounded up to 5, and same all the way down
    if (starsLeft > 0 && 1 - starsLeft < epsilon) return { pos: String(i + 1), star: "full" }
    if (starsLeft <= 0) return { pos: String(i + 1), star: "empty" }
    return { pos: `${i}.5`, star: "half" }
  })
}
