import { Fragment } from "react"
import styled from "styled-components"

const ListWrapper = styled.div<{ $direction?: "horizontal" | "vertical" }>`
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.$direction === "horizontal" ? "row" : "column")};
  align-items: center;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--border-color);
`

interface IList<T> {
  children: (item: T) => React.ReactNode
  direction: "horizontal" | "vertical"
  items: T[]
  header?: React.ReactNode
  divider?: boolean
  windowing?: boolean
  className?: string
}

interface IdObj {
  id: string | number
}

/**
 * Takes in a function as a child to render each individual list item.
 *
 * Usage:
 *     <List items={item} direction="vertical">
 *       {(item) => <ListItem><Icon /> <span>{item.label}</span></ListItem>}
 *     </List>
 */
export default function List<T extends IdObj>({
  children,
  direction,
  items,
  header,
  divider,
  windowing,
  className = "",
}: IList<T>) {
  return (
    <ListWrapper $direction={direction} className={className}>
      {header ? header : null}
      {header && divider ? <Divider /> : null}
      {items.map((item) => (
        <Fragment key={item.id}>{children(item)}</Fragment>
      ))}
      {/* {items.map((item) => (
        <ListContent key={item.id} $direction={direction} className={className}>
          <>{children(item)}</>
        </ListContent>
      ))} */}
    </ListWrapper>
  )
}
