import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const AppStore: React.FC<IIcon> = ({
  size = 48,
  fillNormal = "var(--font-color-less-dark)",
  fillTilted = "var(--color-secondary)",
  strokeNormal = "none",
  strokeTilted = "none",
  stroke = "#444444",
  strokeWidth = 1,
  strokeLinecap = "round",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  // stroke = "#444444",
  // strokeWidth = 2,
  // strokeLinecap = "square",
  // strokeMiterlimit = 10,
  // strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g transform="translate(0, 0)">
        <path
          d="M18,3H5A2,2,0,0,0,3,5V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V5A2,2,0,0,0,18,3ZM5,18V5H18l0,13Z"
          fill={fillNormal}
          stroke={strokeNormal}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M46.451,10.173,37.827,1.549a1.878,1.878,0,0,0-2.654,0l-8.624,8.624a1.878,1.878,0,0,0,0,2.654l8.624,8.624a1.878,1.878,0,0,0,2.654,0l8.624-8.624A1.878,1.878,0,0,0,46.451,10.173Z"
          fill={fillTilted}
          stroke={strokeTilted}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M43,28H30a2,2,0,0,0-2,2V43a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V30A2,2,0,0,0,43,28ZM30,43V30H43l0,13Z"
          fill={fillNormal}
          stroke={strokeNormal}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M18,28H5a2,2,0,0,0-2,2V43a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V30A2,2,0,0,0,18,28ZM5,43V30H18l0,13Z"
          fill={fillNormal}
          stroke={strokeNormal}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeLinejoin={strokeLinejoin}
        ></path>
      </g>
    </IconWrapper>
  )
}

export default AppStore
