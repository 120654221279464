import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Preferences: React.FC<IIcon> = ({
  size = 64,
  fill = "none",
  stroke = "var(--font-color-less-dark)",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeLinejoin = "miter",
  strokeMiterlimit = 10,
  className = "",
  ...props
}) => (
  <IconWrapper size={size} className={className} {...props}>
    <g strokeWidth={strokeWidth} transform="translate(0, 0)">
      <line
        x1="34"
        y1="12"
        x2="61"
        y2="12"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <line
        x1="3"
        y1="12"
        x2="12"
        y2="12"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <circle
        cx="20"
        cy="12"
        r="8"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></circle>
      <line
        x1="34"
        y1="52"
        x2="61"
        y2="52"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <line
        x1="3"
        y1="52"
        x2="12"
        y2="52"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <circle
        cx="20"
        cy="52"
        r="8"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></circle>
      <line
        x1="3"
        y1="32"
        x2="30"
        y2="32"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <line
        x1="52"
        y1="32"
        x2="61"
        y2="32"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <circle
        cx="44"
        cy="32"
        r="8"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></circle>
    </g>
  </IconWrapper>
)

export default Preferences
