import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSVG = styled.svg``

export default function Success({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  className,
}: IIcon) {
  return (
    <StyledSVG
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <path
          fill={fill}
          stroke={stroke}
          strokeLinecap={"butt"}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          d="M41.3,18.7c0.5,1.7,0.7,3.4,0.7,5.3c0,11-9,20-20,20S2,35,2,24S11,4,22,4c3.9,0,7.5,1.1,10.5,3"
        ></path>
        <polyline
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          points="14,20 22,28 44,6 "
        ></polyline>
      </g>
    </StyledSVG>
  )
}
