import { Card, Spacer } from "components"
import styled from "styled-components"
import { noop } from "utils/misc"

import { KeypadSizes } from "types/ui"

const KeyPadWrapper = styled((props) => <Card {...props} />)<{ $size: KeypadSizes; $fullWidth?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--pure-white);
  transition: all 0.2s ease;

  ${(props) => `
    height: var(--keypad-height-${props.$size});
    width: var(--keypad-width-${props.$fullWidth ? "full-width" : props.$size});
  `}

  &:hover {
    background: var(--background-color-lightest-blue);
  }
`

interface IKeyPad {
  size?: KeypadSizes
  elevation?: string
  fullWidth?: boolean
  icon?: React.ReactNode
  iconLocation?: "top" | "bot"
  text?: React.ReactNode
  onClick?: () => void
  className?: string
}
export default function KeyPad({
  size = "md",
  elevation = "low",
  fullWidth = false,
  icon,
  iconLocation = "top",
  text,
  onClick = noop,
  className = "",
}: IKeyPad) {
  return (
    <KeyPadWrapper
      $fullWidth={fullWidth}
      $size={size}
      onClick={onClick}
      className={className}
      role="button"
      aria-label={text}
      elevation={elevation}
      // tabIndex={-1}
    >
      <Spacer size="md" />
      {icon && iconLocation === "top" ? (
        <>
          {icon}
          <Spacer size="md" />
        </>
      ) : null}

      <p>{text}</p>

      {icon && iconLocation === "bot" ? (
        <>
          <Spacer size="md" />
          {icon}
        </>
      ) : null}
      <Spacer size="md" />
    </KeyPadWrapper>
  )
}
