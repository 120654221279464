import React, { forwardRef, useState, useRef, FocusEvent } from "react"
import styled from "styled-components"
import { SmallRedText } from "components"
import { mergeRefs } from "utils/ui"

type InputState = "error" | "focused" | "success" | "default"

const InputWrapper = styled.div<{
  $inputState?: InputState
  $hasIconLeft?: React.ReactNode
  $hasIconRight?: React.ReactNode
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const InputContainer = styled.div<{
  $inputState?: InputState
  $hasIconLeft?: React.ReactNode
  $hasIconRight?: React.ReactNode
  $fullWidth?: boolean
}>`
  display: flex;
  align-items: center;
  height: calc(var(--input-base-height) - 6px);
  width: 100%;
  border-radius: var(--input-border-radius);
  max-width: ${(props) => (props.$fullWidth ? "100%" : "var(--input-base-width)")};
  padding-left: ${(props) => (props.$hasIconLeft ? "var(--input-padding-left)" : "")};
  padding-right: ${(props) => (props.$hasIconRight ? "var(--input-padding-right)" : "var(--input-padding-right)")};
  padding-top: var(--input-padding-top);
  padding-bottom: var(--input-padding-bot);
  background-color: var(--input-background-color-default);
  border: ${(props) => `solid 1px var(--input-border-color-${props.$inputState})`};
  cursor: text;

  &:hover {
    ${(props) => (props.$inputState === "default" ? `background-color: var(--input-background-color-hover);` : ``)}
  }
`
const StyledInput = styled.input`
  font-size: var(--input-font-size);
  color: var(--font-color-less-dark);
  background-color: transparent;
  outline: none;
  border: none;
  flex: 1;
  margin-left: var(--input-padding-left);

  &::placeholder {
    color: var(--input-font-placeholder-color);
  }
`
const StyledLabel = styled.label`
  // font-size: var(--input-label-text-size);
  font-size: 13px;
  transform: translateY(4px);
  color: var(--input-label-text-color);
  // margin-bottom: var(--input-label-text-margin-bot);
  text-transform: uppercase;
  margin-left: var(--input-border-radius);
`
const HelperText = styled.small<{
  $inputState?: InputState
}>`
  font-size: var(--input-helper-text-size);
  // margin-top: var(--input-helper-text-margin-top);
  color: ${(props) => `var(--input-helper-text-color-${props.$inputState})`};
  margin-left: var(--input-border-radius);
`

interface IInput {
  label?: string | React.ReactNode
  name: string
  placeholder?: string
  noLabel?: boolean
  noHelperText?: boolean
  helperText?: string
  successText?: string
  includeRequiredStar?: boolean
  type?: "text" | "email" | "tel" | "password" | "number" | "date" | "datetime-local" | "hidden"
  fullWidth?: boolean
  success?: boolean
  register?: any
  onBlur?: (e: FocusEvent) => void
  onFocus?: (e: FocusEvent) => void
  autoComplete?: string
  providedError?: string | undefined | null
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  required?: boolean
  className?: string
}
const UncontrolledInput = forwardRef<any, IInput>(
  (
    {
      name,
      label = "",
      type = "text",
      helperText,
      noLabel = false,
      noHelperText = false,
      fullWidth = false,
      success = false,
      successText,
      includeRequiredStar = false,
      onBlur = () => {},
      onFocus = () => {},
      register,
      autoComplete,
      providedError,
      placeholder,
      required,
      iconLeft,
      iconRight,
      className = "",
    },
    ref
  ) => {
    const localRef = useRef<HTMLInputElement | null>(null)
    const [focused, setFocused] = useState<boolean>(false)

    const getInputState = (): InputState => {
      if (providedError) return "error"
      if (focused) return "focused"
      if (success) return "success"
      return "default"
    }

    const inputState = getInputState()

    const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      onBlur(e)
      return e
    }

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      onFocus(e)
      return e
    }

    const focusOnInput = () => {
      localRef.current?.focus()
    }

    const renderLabel = () => {
      if (noLabel) return null

      return (
        <StyledLabel htmlFor={name}>
          {label}
          {includeRequiredStar ? <SmallRedText>*</SmallRedText> : null}
        </StyledLabel>
      )
    }

    const renderHelperText = () => {
      if (noHelperText) return null

      const helperTextContent =
        {
          error: providedError,
          success: successText || helperText,
          default: helperText,
        }[inputState] || helperText

      return <HelperText $inputState={inputState}>{helperTextContent}</HelperText>
    }

    return (
      <InputWrapper
        $hasIconLeft={iconLeft}
        $hasIconRight={iconRight}
        $inputState={inputState}
        onClick={focusOnInput}
        className={className}
      >
        {renderLabel()}
        <InputContainer
          $hasIconLeft={iconLeft}
          $hasIconRight={iconRight}
          $inputState={inputState}
          $fullWidth={fullWidth}
          className="styled__input-container"
        >
          {iconLeft ? iconLeft : null}
          <StyledInput
            className="styled__input"
            name={name}
            autoComplete={autoComplete}
            onBlur={(e) => handleOnBlur(e)}
            onFocus={(e) => handleOnFocus(e)}
            // ref={ref}
            ref={mergeRefs(ref, localRef)}
            {...register}
            type={type}
            placeholder={placeholder}
            required={required}
          />
          {iconRight ? iconRight : null}
        </InputContainer>
        {renderHelperText()}
      </InputWrapper>
    )
  }
)

export default UncontrolledInput
