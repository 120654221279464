import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Heart: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 1,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <path
        d="M42.485,8.544A12,12,0,0,0,24,10.4,12,12,0,1,0,5.515,25.515L24,44,42.485,25.515A12,12,0,0,0,42.485,8.544Z"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
    </IconWrapper>
  )
}

export default Heart
