import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Grid: React.FC<IIcon> = ({
  size = 64,
  fill = "none",
  stroke = "#6E7491",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeLinejoin = "miter",
  strokeMiterlimit = 10,

  className = "",
  ...props
}) => (
  <IconWrapper size={size} className={className} {...props}>
    <g strokeWidth={strokeWidth} transform="translate(0, 0)">
      <rect
        x="5"
        y="5"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="26"
        y="5"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="47"
        y="5"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="5"
        y="26"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="26"
        y="26"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="47"
        y="26"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="5"
        y="47"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="26"
        y="47"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
      <rect
        x="47"
        y="47"
        width="12"
        height="12"
        rx={2}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></rect>
    </g>
  </IconWrapper>
)

export default Grid
