import { useRef, useCallback } from "react"
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"
import { useEventListener } from "hooks/common/useEventListener"
import { Keypad, Icon } from "components"
import styled from "styled-components"

const NavbarDropdownWrapper = styled(motion.div)`
  position: fixed;
  right: 0;
  top: var(--navbar-height);
  z-index: var(--z-index-navbar);
  width: 100%;
  display: flex;
  flex-direction: column;
`
const NavbarDropdownContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  background: var(--pure-white);
  flex: 1;
`
const MenuContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px var(--background-color-lightest-blue);
  box-shadow: var(--elevation-4);
`
const StyledMenu = styled(motion.ul)`
  height: 100%;
  width: 100%;
  list-style-type: none;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  max-width: 600px;

  padding: 1rem 0;
`
const StyledMenuItem = styled(motion.li)`
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  & svg {
    height: 30px;
    width: 30px;
  }
`
const StyledKeypad = styled((props) => <Keypad {...props} />)`
  width: 100%;
  & p {
    color: var(--font-color-less-dark);
  }
`

type Item = { id: string; label: string; route: () => string; icon?: React.ReactNode }

const rowSize = 135
const menus = {
  grid: {
    name: "grid",
    // height: 3 * 105 + 50,
    height: 3 * rowSize + 30,
    items: () => [
      {
        id: "events",
        label: "Events",
        route: () => "/events/",
        icon: <Icon iconName="exhibit" fillRect="var(--color-secondary-a40)" stroke={"var(--font-color-less-dark)"} />,
      },
      {
        id: "providers",
        label: "Providers",
        route: () => "/providers/",
        icon: (
          <Icon
            iconName="school"
            fillLight="var(--color-primary)"
            fillLightGrey="var(--pure-white)"
            fillDark="var(--background-color-very-light-blue)"
            fillGrey="var(--background-color-very-light-blue)"
            strokeGrey="var(--font-color-less-dark)"
          />
        ),
      },
      {
        id: "reviews",
        label: "Reviews",
        route: () => "/reviews/",
        icon: (
          <Icon
            iconName="comments"
            fillSecondComment={"var(--color-secondary-a40)"}
            stroke={"var(--font-color-less-dark)"}
          />
        ),
      },
      {
        id: "data-explorer",
        label: "Data Explorer",
        route: () => "/data-explorer/",
        icon: <Icon iconName="statistics" fillSecond="var(--color-secondary)" stroke={"var(--font-color-less-dark)"} />,
      },
      {
        id: "search",
        label: "Search",
        route: () => "/search/",
        icon: (
          <Icon
            iconName="search"
            strokeWidth={1}
            strokeSemiCircle={"var(--color-primary-a80)"}
            stroke={"var(--font-color-less-dark)"}
          />
        ),
      },
      {
        id: "blog",
        label: "Blog",
        // route: () => "/blog/",
        route: () => `https://velup-sanity-blog.vercel.app/`,
        icon: (
          <Icon
            iconName="browser_paragraph"
            fillRect="var(--color-secondary-a40)"
            stroke={"var(--font-color-less-dark)"}
          />
        ),
      },
      {
        id: "support",
        label: "Support",
        route: () => "/support/",
        icon: <Icon iconName="lifesaver" fill={"var(--color-accent)"} stroke="black" strokeWidth={0.5} />,
      },
      { id: "contact", label: "Contact Us", route: () => "/contact-us/", icon: <Icon iconName="mobile_contact" /> },
      { id: "manage", label: "Manage", route: () => "/manage/", icon: <Icon iconName="app_store" /> },
    ],
  },
  bell: {
    name: "bell",
    // height: 3 * 55 + 30,
    // height: 1 * 135,
    height: 1 * rowSize + 30,
    items: () => [
      {
        id: "notifications",
        label: "Notifications",
        route: () => "/notifications/",
        icon: <Icon iconName="bell" stroke={"var(--font-color-less-dark)"} />,
      },
      {
        id: "settings",
        label: "Settings",
        route: () => "/settings/",
        icon: <Icon iconName="settings" stroke={"var(--font-color-less-dark)"} />,
      },
      {
        id: "messages",
        label: "Messages",
        // route: () => "/messages/",
        route: () => "/notifications/",
        icon: <Icon iconName="email" strokeWidth={0.75} stroke={"var(--font-color-less-dark)"} />,
      },
    ],
  },
  auth: {
    name: "auth",
    // height: 5 * 55 + 30,
    height: 1 * rowSize + 30,
    items: () => [
      {
        id: "profile",
        label: "Profile",
        route: () => "/profile/",
        icon: <Icon iconName="user_circle" stroke="var(--font-color-less-dark)" />,
      },
      { id: "account", label: "Account", route: () => "/account/", icon: <Icon iconName="preferences" /> },
      {
        id: "logout",
        label: "Logout",
        route: () => "/signout/",
        icon: <Icon iconName="logout" stroke="var(--font-color-less-dark)" />,
      },
    ],
  },
}

const navbarVariants = Object.keys(menus).reduce(
  (acc, menu) => {
    const selectedMenu = menus[menu]
    acc[menu] = {
      height: selectedMenu.height,
      opacity: 1,
      rotateX: 0,
    }
    return acc
  },
  {
    closed: {
      height: 0,
      opacity: 0,
      rotateX: -15,
    },
  }
)

const menuVariants = {
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    opacity: 0,
    x: "200%",
  },
}

const Menu = ({ menu, navigateInternal }) => {
  return (
    <StyledMenu layoutId={`menu`}>
      {menu.items().map((item: Item) => (
        <StyledMenuItem key={item.id} onClick={() => navigateInternal(item.route())} role="button">
          <StyledKeypad
            size="xl"
            text={item.label}
            icon={item.icon}
            // onClick={() => console.log(item.route())}
            // onClick={() => router.push(item.route())}
          />
        </StyledMenuItem>
      ))}
    </StyledMenu>
  )
}

export default function NavbarDropdown({ dropdownMenuOpen, navigateInternal, closeDropdownMenu, navbarRef }) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const selectedMenu = menus[dropdownMenuOpen]

  const listener = useCallback(
    (e: PointerEvent) => {
      if (
        wrapperRef?.current &&
        !wrapperRef.current.contains(e?.target as Node) &&
        navbarRef?.current &&
        !navbarRef.current.contains(e?.target as Node)
      ) {
        if (dropdownMenuOpen) {
          closeDropdownMenu(dropdownMenuOpen)
        }
      }
    },
    [wrapperRef, dropdownMenuOpen]
  )

  useEventListener("click", listener, document.body)

  return (
    <NavbarDropdownWrapper
      // ref={wrapperRef}
      initial={closed}
      animate={dropdownMenuOpen ? dropdownMenuOpen : "closed"}
      variants={navbarVariants}
    >
      <AnimateSharedLayout type="crossfade">
        <NavbarDropdownContainer ref={wrapperRef}>
          <AnimatePresence initial={false} exitBeforeEnter>
            {Object.keys(menus).map((menu) =>
              dropdownMenuOpen === menu ? (
                <MenuContainer
                  initial={closed}
                  key={menu}
                  variants={menuVariants}
                  animate={dropdownMenuOpen === menu ? "open" : "closed"}
                >
                  <Menu
                    navigateInternal={navigateInternal}
                    menu={selectedMenu}
                    // other
                  />
                </MenuContainer>
              ) : null
            )}
          </AnimatePresence>
        </NavbarDropdownContainer>
      </AnimateSharedLayout>
    </NavbarDropdownWrapper>
  )
}
