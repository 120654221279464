import ReactMarkdown from "react-markdown"
import styled from "styled-components"

const StyledP = styled.p`
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 16px;
`

interface IMarkdownDisplay {
  source: string
  disallowedTypes?: (
    | "blockquote"
    // | "tablehead"
    // | "tablebody"
    | "code"
    | "html"
    | "link"
    | "strong"
    | "table"
    | "image"
    | "text"
    | "tableRow"
    | "tableCell"
    | "definition"
    | "inlineCode"
    | "linkReference"
    | "paragraph"
    | "heading"
  )[]
  linkComponent?: any
  paragraphComponent?: any
  headingComponent?: any
}
export default function MarkdownDisplay({
  source,
  disallowedTypes = [
    "table",
    // "tablehead",
    // "tablebody",
    "tableRow",
    "tableCell",
    "definition",
    "inlineCode",
    "code",
    "link",
    "linkReference",
  ],
  linkComponent = StyledP,
  paragraphComponent = StyledP,
  headingComponent = StyledP,
  ...props
}: IMarkdownDisplay) {
  return (
    <ReactMarkdown
      source={source}
      disallowedTypes={disallowedTypes}
      renderers={{
        paragraph: paragraphComponent,
        link: linkComponent,
        linkReference: StyledP,
        heading: headingComponent,
      }}
      {...props}
    />
  )
}
