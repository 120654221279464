import { useSelect } from "downshift"
// if list gets too big, look at https://www.downshift-js.com/use-select#virtualizing-items-with-react-virtual
import { motion } from "framer-motion"
import { Card, IconChevronRight } from "components"
import { useUniqueId } from "hooks/common/useUniqueId"
import styled from "styled-components"

type DropdownStatus = "selected" | "focused" | "inactive" | "error" | "default"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`
const CardLabel = styled(Card)<{ $fullWidth?: boolean; $dropdownStatus: DropdownStatus }>`
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: var(--dropdown-border-radius);
  background: var(--dropdown-background-color);
  width: 100%;

  ${(props) => `
    border: solid 1px var(--dropdown-border-color-${props.$dropdownStatus});
    max-width: ${props.$fullWidth ? "100%" : "var(--dropdown-standard-width)"};

    & path {
      fill: var(--dropdown-font-color-${props.$dropdownStatus});
    }
  
    &:hover {
      ${
        props.$dropdownStatus === "inactive"
          ? "background: var(--dropdown-background-color);"
          : props.$dropdownStatus === "error"
          ? "background: var(--dropdown-background-color-hover-error);"
          : "background: var(--dropdown-background-color-hover);"
      }
    }    
  `}
`
const StyledButton = styled.button<{ $isDisabled?: boolean }>`
  flex: 1;
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.8rem;

  ${(props) => props.$isDisabled && `cursor: not-allowed;`}
`
const StyledAngleDownIcon = styled((props) => <IconChevronRight {...props} />)<{ $isOpen?: boolean }>`
  transition: transform 0.4s var(--juiced-up-ease);
  will-change: transform;
  transform: scale(0.5) rotate(90deg);
  ${(props) => `transform: scale(0.5) rotate(${props.$isOpen ? "-90" : "90"}deg);`}
  flex-shrink: 0;
  flex-basis: 24px;
`
const InputLabel = styled.span<{ $dropdownStatus?: DropdownStatus }>`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  pointer-events: none;
  width: calc(100% - 24px);

  color: var(--dropdown-font-color-${(props) => props.$dropdownStatus});
`
const Menu = styled(motion.ul)<{ $fullWidth?: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.$fullWidth ? "100%" : "var(--dropdown-standard-width)")};
  top: 100%;
  position: absolute;
  z-index: var(--z-index-modal);
  flex-direction: column;
  background: var(--dropdown-background-color);
  border-radius: var(--dropdown-border-radius);
  padding-top: 10px;
  margin-top: 0.1rem;
  border: solid 1px var(--border-color);
  overflow-y: scroll;
  box-shadow: var(--dropdown-menu-elevation);
`
const StyledMenuItem = styled.li<{ $isHighlighted?: boolean; $dropdownStatus: DropdownStatus }>`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: var(--z-index-modal);
  padding: 0 var(--spacing-sm);

  & button {
    padding: 0 var(--spacing-sm);
    /* color: var(--dropdown-font-color-${(props) => props.$dropdownStatus}); */
    color: var(--dropdown-font-color-default);
    font-family: var(--font-primary);
    flex: 1;
    font-size: 14px;
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.$isHighlighted &&
    `
    & button {
      background: var(--dropdown-background-color-hover);
      color: var(--dropdown-font-color-hover);
    }
  `}
`
const HelperText = styled.small<{
  $dropdownStatus?: DropdownStatus
}>`
  font-size: var(--dropdown-helper-text-font-size);
  color: ${(props) => `var(--dropdown-helper-text-color-${props.$dropdownStatus})`};
  margin-left: var(--dropdown-border-radius);
  position: absolute;
  top: 100%;
`

const menuVariants = {
  isOpen: (h: number) => ({
    opacity: 1,
    rotateX: 0,
    transition: {
      duration: 0.3,
    },
    height: h,
  }),
  isClosed: {
    opacity: 0,
    rotateX: -15,
    transition: {
      duration: 0.3,
      delay: 0.1,
    },
    height: 0,
  },
}

type Item = { label: string; value: any }
const itemToString = (item: Item): string => (item ? item.value : "")
const defaultRenderItemLabel = (item: Item): string | JSX.Element => {
  return item?.label?.trim() === "" ? <>&mdash;</> : item?.label
}

type DropdownChanges = { highlightedIndex?: number; inputValue?: string; isOpen?: boolean; selectedItem?: Item | null }

interface IDropdownSelect<T extends Item> {
  items: T[]
  label: string
  isDisabled?: boolean
  selectedItem: T
  onSelect: (item: T) => void
  menuHeight?: number
  providedError?: string
  helperText?: string
  renderItemLabel?: (item: Item, ctx?: string) => string | JSX.Element
  className?: string
}
export default function DropdownSelect({
  label,
  items,
  isDisabled = false,
  selectedItem,
  onSelect,
  menuHeight,
  providedError,
  helperText,
  renderItemLabel = defaultRenderItemLabel,
  className = "",
}: IDropdownSelect<Item>) {
  const uniqueId = useUniqueId("dropdown-select")

  const handleOnSelect = (changes: DropdownChanges) => {
    onSelect(changes.selectedItem)
  }

  const selectableItems = items.filter((item: Item) => selectedItem?.value !== item.value)

  // id="autocomplete"
  // labelId="autocomplete-label"
  // inputId="autocomplete-input"
  // menuId="autocomplete-menu"

  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    id: uniqueId,
    labelId: `${uniqueId}-label`,
    menuId: `${uniqueId}-menu`,
    items: selectableItems,
    selectedItem,
    itemToString,
    onSelectedItemChange: handleOnSelect,
  })

  const getDropdownStatus = (): DropdownStatus => {
    if (isDisabled) return "inactive"
    if (providedError) return "error"
    if (isOpen) return "focused"
    return "default"
  }

  const dropdownStatus = getDropdownStatus()
  const shouldOpen = isOpen && !isDisabled
  const customMenuHeight = menuHeight ? menuHeight : (selectableItems?.length || 1) * 50 + 10 * 2

  return (
    <Wrapper className={className}>
      <CardLabel $dropdownStatus={dropdownStatus} noPadding elevation={"low"} className="dropdown-card">
        <StyledButton $isDisabled={isDisabled} {...getToggleButtonProps()}>
          <>
            <InputLabel {...getLabelProps()} $dropdownStatus={dropdownStatus}>
              {selectedItem ? renderItemLabel(selectedItem, "dropdown-label") : label}
            </InputLabel>
          </>
          <StyledAngleDownIcon $isOpen={shouldOpen} />
        </StyledButton>
      </CardLabel>

      {helperText && (
        <HelperText $dropdownStatus={dropdownStatus}>{providedError ? providedError : helperText}</HelperText>
      )}

      <Menu
        initial="isClosed"
        animate={shouldOpen ? "isOpen" : "isClosed"}
        variants={menuVariants}
        custom={customMenuHeight}
        {...getMenuProps()}
      >
        {selectableItems.map((item: Item, idx: number) => (
          <StyledMenuItem
            key={`${item.value}`}
            $isHighlighted={highlightedIndex === idx}
            $dropdownStatus={dropdownStatus}
            {...getItemProps({ item, index: idx })}
          >
            <StyledButton>{renderItemLabel(item)}</StyledButton>
          </StyledMenuItem>
        ))}
        <div tabIndex={0} />
      </Menu>
    </Wrapper>
  )
}
