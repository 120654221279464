// import { createContext, useContext } from "react"
import { ChartDimensions } from "types/charts"
import { isDefined } from "utils/parseUtils"

// const ChartContext = createContext({})
// export const useChartDimensions = () => useContext(ChartContext)

type SVGProps = {
  height: number
  width: string | number
  viewBox?: string
}

interface IChart {
  dimensions: ChartDimensions
  children: React.ReactNode
  viewBox?: string
  noGTransform?: boolean
  fullWidth?: boolean
}
const Chart: React.FC<IChart> = ({ dimensions, children, noGTransform, viewBox, fullWidth }) => {
  const gTransform = noGTransform ? `translate(0, 0)` : `translate(${dimensions.marginLeft}, ${dimensions.marginTop})`

  const svgProps: SVGProps = {
    height: dimensions.height,
    width: fullWidth ? "100%" : dimensions.width,
  }
  if (isDefined(viewBox)) {
    svgProps.viewBox = viewBox
  }

  return (
    <svg {...svgProps}>
      <g transform={gTransform}>
        <>{children}</>
      </g>
    </svg>
  )
}

export default Chart
