import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSVG = styled.svg``

const Email: React.FC<IIcon> = ({
  size = 24,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  // strokeLinejoin = "miter",
  className = "",
}) => {
  return (
    <StyledSVG
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <polyline
        points="19 7 12 14 5 7"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit={strokeMiterlimit}
        strokeLinecap={strokeLinecap}
      />
      <rect
        x="1"
        y="3"
        width="22"
        height="18"
        rx="2"
        ry="2"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit={strokeMiterlimit}
        strokeLinecap={strokeLinecap}
      />
      <line
        x1="7"
        y1="15"
        x2="5"
        y2="17"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit={strokeMiterlimit}
        strokeLinecap={strokeLinecap}
      />
      <line
        x1="17"
        y1="15"
        x2="19"
        y2="17"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit={strokeMiterlimit}
        strokeLinecap={strokeLinecap}
      />
    </StyledSVG>
  )
}

export default Email
