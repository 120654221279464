import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Office: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <polyline
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        points=" 36,17 36,6 12,6 12,23 "
      ></polyline>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="18"
        y1="46"
        x2="30"
        y2="46"
      ></line>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="24"
        y1="2"
        x2="24"
        y2="6"
      ></line>{" "}
      <rect
        x="2"
        y="28"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        width="16"
        height="18"
      ></rect>{" "}
      <rect
        x="30"
        y="22"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        width="16"
        height="24"
      ></rect>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="36"
        y1="28"
        x2="40"
        y2="28"
      ></line>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="36"
        y1="34"
        x2="40"
        y2="34"
      ></line>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="36"
        y1="40"
        x2="40"
        y2="40"
      ></line>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="8"
        y1="34"
        x2="12"
        y2="34"
      ></line>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="21"
        y1="14"
        x2="27"
        y2="14"
      ></line>{" "}
      <line
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        x1="8"
        y1="40"
        x2="12"
        y2="40"
      ></line>
    </IconWrapper>
  )
}

export default Office
