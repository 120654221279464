import NextHead from "next/head"

export default function Head({
  title = "Velup.io",
  description = "Where education professionals source their development",
  url = "https://www.velup.io",
  logo = "/assets/images/velup_blue_logo_192x192.png",
  ogImage = "",
}) {
  return (
    <NextHead>
      <title>{title}</title>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      {/* <meta name="robots" content="noindex" />         */}
      <meta name="description" content={description} />
      <meta name="image" content={logo} />
      <link rel="icon" sizes="192x192" href={logo} />
      {/* <link rel="icon" sizes="192x192" href="/static/touch-icon.png" />
      <link rel="apple-touch-icon" href="/static/touch-icon.png" />
      <link rel="mask-icon" href="/static/favicon-mask.svg" color="#49B882" />
      <link rel="icon" href="/static/favicon.ico" /> */}

      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:site" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={ogImage || logo} />
      <meta property="og:image" content={ogImage || logo} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* <script src="https://js.stripe.com/v3/" />     */}
    </NextHead>
  )
}
