import { useRef, useEffect } from "react"
import { Portal } from "react-portal"
import { Button, Card, IconClose, Spacer } from "components"
import styled from "styled-components"
import { noop } from "utils/misc"

const ModalOverlay = styled.div`
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(230, 230, 235, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  width: 100vw;
  height: 100vh;
`
const ModalCard = styled(Card)`
  background: var(--modal-background);
  border-radius: var(--modal-border-radius);
  box-shadow: var(--modal-elevation);
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 1fr 80px;
  grid-template-areas: "main close" "footer footer";

  max-width: var(--modal-card-max-width);
`
const ModalCardMain = styled.div`
  grid-area: main;
`
const ModalCardFooter = styled.div`
  grid-area: footer;
`
const CloseButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: close;
`

const ModalCardHeader = styled.div`
  font-size: var(--modal-header-font-size);
  color: var(--modal-header-font-color);
  padding-top: var(--modal-padding-top);
  padding-right: var(--modal-padding-right);
  padding-left: var(--modal-padding-left);
`
const ModalCardBody = styled.div`
  font-size: var(--modal-content-font-size);
  color: var(--modal-content-font-color);
  padding-right: var(--modal-padding-right);
  padding-left: var(--modal-padding-left);
  padding-bottom: var(--modal-padding-bot);
  padding-top: var(--modal-padding-top);
`
const ModalCardActions = styled.div<{ $hasHeader?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--modal-padding-right);
  padding-left: var(--modal-padding-left);
  padding-bottom: var(--modal-padding-bot);
  padding-top: var(--modal-padding-top);
  ${(props) => (!props.$hasHeader ? "border-top: solid 1px var(--border-color);" : "")}
`
const StyledIconClose = styled((props) => <IconClose {...props} />)`
  transform: scale(0.5);
  cursor: pointer;
`

interface IModal {
  isOpen: boolean
  onClose: () => void
  header?: React.ReactNode
  onCancel?: () => void
  onConfirm?: () => void
  className?: string
}
const Modal: React.FC<IModal> = ({
  isOpen,
  onClose,
  header,
  onCancel = noop,
  onConfirm = noop,
  className = "",
  children,
}) => {
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const cardRef = useRef<HTMLDivElement | null>(null)

  const handleOnCancel = () => {
    onCancel()
    onClose()
  }

  const handleOnConfirm = () => {
    onConfirm()
    onClose()
  }

  useEffect(() => {
    const closeOnOverlayClick = (event: any) => {
      if (cardRef.current && overlayRef.current) {
        if (!cardRef.current.contains(event.target) && overlayRef.current.contains(event.target)) {
          if (isOpen) {
            onClose()
          }
        }
      }
    }

    if (isOpen) {
      document.addEventListener("click", closeOnOverlayClick)
    } else {
      document.removeEventListener("click", closeOnOverlayClick)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <Portal>
      <ModalOverlay ref={overlayRef}>
        <ModalCard noPadding ref={cardRef} className={className}>
          <ModalCardMain>
            {header && <ModalCardHeader>{header}</ModalCardHeader>}

            <ModalCardBody>
              <>{children}</>
            </ModalCardBody>
          </ModalCardMain>

          <CloseButtonColumn>
            <StyledIconClose onClick={() => onClose()} />
          </CloseButtonColumn>

          <ModalCardFooter>
            <ModalCardActions $hasHeader={Boolean(header)}>
              <Button buttonSize="sm" onClick={handleOnCancel} buttonType="secondary">
                Cancel
              </Button>
              <Spacer size="md" />
              <Button buttonSize="sm" onClick={handleOnConfirm} buttonType="primary">
                Confirm
              </Button>
            </ModalCardActions>
          </ModalCardFooter>
        </ModalCard>
      </ModalOverlay>
    </Portal>
  )
}

export default Modal
