import React from "react"
import styled from "styled-components"
import { IIcon } from "types/ui"
import { noop } from "utils/misc"

const StyledSVG = styled.svg`
  cursor: pointer;
`

const IconWrapper: React.FC<IIcon> = ({
  iconName = "search",
  size = 64,
  className = "",
  onClick = noop,
  onMouseEnter = noop,
  onMouseLeave = noop,
  children,
  ...props
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.isValidElement(child)
      ? React.cloneElement(child, {
          ...props,
        })
      : child
  })

  return (
    <StyledSVG
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
    >
      {childrenWithProps}
    </StyledSVG>
  )
}

export default IconWrapper
