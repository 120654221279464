import React, { forwardRef, useState, useRef, FocusEvent } from "react"
import styled from "styled-components"
import { SmallRedText } from "components"
import { isValidEmail } from "utils/validation"
import { isDefined } from "utils/parseUtils"
import { mergeRefs } from "utils/ui"

type InputState = "error" | "focused" | "success" | "default"

const InputWrapper = styled.div<{
  $inputState?: InputState
  $hasIconLeft?: React.ReactNode
  $hasIconRight?: React.ReactNode
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const InputContainer = styled.div<{
  $inputState?: InputState
  $hasIconLeft?: React.ReactNode
  $hasIconRight?: React.ReactNode
  $fullWidth?: boolean
}>`
  display: flex;
  align-items: center;
  height: var(--input-base-height);
  width: 100%;
  border-radius: var(--input-border-radius);
  max-width: ${(props) => (props.$fullWidth ? "100%" : "var(--input-base-width)")};
  padding-left: ${(props) => (props.$hasIconLeft ? "var(--input-padding-left)" : "")};
  padding-right: ${(props) => (props.$hasIconRight ? "var(--input-padding-right)" : "var(--input-padding-right)")};
  padding-top: var(--input-padding-top);
  padding-bottom: var(--input-padding-bot);
  background-color: var(--input-background-color-default);
  border: ${(props) => `solid 1px var(--input-border-color-${props.$inputState})`};
  cursor: text;

  &:hover {
    ${(props) => (props.$inputState === "default" ? `background-color: var(--input-background-color-hover);` : ``)}
  }
`
const StyledInput = styled.input`
  font-size: var(--input-font-size);
  color: var(--font-color-less-dark);
  background-color: transparent;
  outline: none;
  border: none;
  flex: 1;
  margin-left: var(--input-padding-left);

  &::placeholder {
    color: var(--input-font-placeholder-color);
  }
`
const StyledLabel = styled.label`
  font-size: var(--input-label-text-size);
  color: var(--input-label-text-color);
  margin-bottom: var(--input-label-text-margin-bot);
  text-transform: uppercase;
  margin-left: var(--input-border-radius);
`
const HelperText = styled.small<{
  $inputState?: InputState
}>`
  font-size: var(--input-helper-text-size);
  margin-top: var(--input-helper-text-margin-top);
  color: ${(props) => `var(--input-helper-text-color-${props.$inputState})`};
  margin-left: var(--input-border-radius);
`

interface IInput {
  label: string | React.ReactNode
  value: any
  name: string
  placeholder: string
  noLabel?: boolean
  noHelperText?: boolean
  helperText?: string
  successText?: string
  includeRequiredStar?: boolean
  type?: "text" | "email" | "tel" | "password" | "number" | "date" | "datetime-local" | "hidden"
  checked?: boolean
  min?: number
  max?: number
  fullWidth?: boolean
  success?: boolean
  onChange?: (v: string) => void
  onBlur?: (e: FocusEvent) => void
  onFocus?: (e: FocusEvent) => void
  // onClick?: (e: any) => void
  formatter?: (val: any) => any
  maxCharacters?: number | boolean | undefined | null
  autoComplete?: string
  providedError?: string | undefined | null
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  required?: boolean
  // validationError?: string
  // validate?: (val: any) => boolean
  className?: string
}
const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      name,
      value,
      label = "",
      type = "text",
      helperText,
      checked,
      min,
      max,
      noLabel = false,
      noHelperText = false,
      fullWidth = false,
      success = false,
      successText,
      includeRequiredStar = false,
      onChange = (text) => console.error("Missing onChange prop: ", text),
      onBlur = () => {},
      onFocus = () => {},
      // onClick = () => {},
      // validate = (val) => true,
      maxCharacters = false,
      formatter = (val) => val,
      autoComplete,
      providedError,
      // validationError = "",
      placeholder,
      required,
      iconLeft,
      iconRight,
      className = "",
    },
    ref
  ) => {
    const localRef = useRef<HTMLInputElement | null>(null)
    const [focused, setFocused] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const getInputState = (): InputState => {
      if (error || providedError) return "error"
      if (focused) return "focused"
      if (success) return "success"
      return "default"
    }

    const inputState = getInputState()

    const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      onBlur(e)
      return e
    }

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      onFocus(e)
      return e
    }

    const focusOnInput = () => {
      localRef.current?.focus()
    }

    const validateValue = (value: any) => {
      // handle any validation here:
      if (type === "email") {
        if (!isValidEmail(value)) {
          setError("email is invalid")
        } else {
          setError(null)
        }
      }
    }

    const handleOnChange = (value: any) => {
      validateValue(value)

      // handle max character limit
      if (maxCharacters) {
        if (value.length > maxCharacters) {
          value = value.slice(0, maxCharacters)
        }
      }

      // call onChange
      onChange(formatter(isDefined(value) ? value : ""))
    }

    const renderLabel = () => {
      if (noLabel) return null

      return (
        <StyledLabel htmlFor={name}>
          {label}
          {includeRequiredStar ? <SmallRedText>*</SmallRedText> : null}
        </StyledLabel>
      )
    }

    const renderHelperText = () => {
      if (noHelperText) return null

      const helperTextContent =
        {
          error: error || providedError,
          success: successText || helperText,
          default: helperText,
        }[inputState] || helperText

      return <HelperText $inputState={inputState}>{helperTextContent}</HelperText>
    }

    return (
      <InputWrapper
        $hasIconLeft={iconLeft}
        $hasIconRight={iconRight}
        $inputState={inputState}
        onClick={focusOnInput}
        className={className}
      >
        {renderLabel()}
        <InputContainer
          $hasIconLeft={iconLeft}
          $hasIconRight={iconRight}
          $inputState={inputState}
          $fullWidth={fullWidth}
          className="styled__input-container"
        >
          {iconLeft ? iconLeft : null}
          <StyledInput
            className="styled__input"
            name={name}
            value={value}
            autoComplete={autoComplete}
            onBlur={(e) => handleOnBlur(e)}
            onFocus={(e) => handleOnFocus(e)}
            checked={checked}
            min={min}
            max={max}
            // ref={ref}
            ref={mergeRefs(ref, localRef)}
            type={type}
            placeholder={placeholder}
            onChange={(e) => handleOnChange(e?.target?.value)}
            required={required}
          />
          {iconRight ? iconRight : null}
        </InputContainer>
        {renderHelperText()}
      </InputWrapper>
    )
  }
)

export default Input
