import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const BrowserParagraph: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  fillRect,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <rect
          x="8"
          y="24"
          fill={fillRect || fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          width="12"
          height="12"
        ></rect>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="26"
          y1="24"
          x2="40"
          y2="24"
        ></line>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="26"
          y1="30"
          x2="40"
          y2="30"
        ></line>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="26"
          y1="36"
          x2="32"
          y2="36"
        ></line>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="8"
          y1="10"
          x2="10"
          y2="10"
        ></line>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="16"
          y1="10"
          x2="18"
          y2="10"
        ></line>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="32"
          y1="10"
          x2="40"
          y2="10"
        ></line>
        <line
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          x1="2"
          y1="16"
          x2="46"
          y2="16"
        ></line>
        <rect
          x="2"
          y="4"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
          width="44"
          height="40"
        ></rect>
      </g>
    </IconWrapper>
  )
}

export default BrowserParagraph
