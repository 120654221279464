import { DropdownItem } from "types/ui"

export const eventTypeDropdownItems: DropdownItem[] = [
  { label: "Any type", value: "any" },
  { label: "Coaching", value: "coaching" },
  { label: "Conference", value: "conference" },
  { label: "Immersive", value: "immersive" },
  { label: "Meetup", value: "meetup" },
  { label: "Talk", value: "talk" },
  { label: "Workshop", value: "workshop" },
]

export const landingChipSearchShortcuts = [
  { label: "Online", path: "/search/?topic=online" },
  { label: "Happening Now", path: "/search/?topic=happening-now" },
  { label: "Near You", path: "/search/?topic=near-you" },
  { label: "Literacy", path: "/search/?topic=literacy" },
  { label: "Computer Science", path: "/search/?topic=computer-science" },
]

export const logoSizeToSrcMapping = {
  xs: `/assets/logos/logo_150x50.svg`,
  sm: `/assets/logos/logo_215x75.svg`,
  md: `/assets/logos/logo_260x90.svg`,
  lg: `/assets/logos/logo_375x130.svg`,
  xl: `/assets/logos/logo_505x175.svg`,
  xxl: `/assets/logos/logo_775x265.svg`,
}
