import styled from "styled-components"

const ColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface IColumn {
  className?: string
}
const Column: React.FC<IColumn> = ({ children, className = "" }) => {
  return (
    <ColumnWrapper className={className}>
      <>{children}</>
    </ColumnWrapper>
  )
}

export default Column
