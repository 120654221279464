import { Ref, RefObject, useState } from "react"
import { IconStar, IconStarHalf, Spacer } from "components"
import { RegisterOptions } from "react-hook-form"
// import { useCreateStars } from "hooks/ui/useCreateStars"
import styled from "styled-components"

const StarsInputWrapper = styled.div``

// const Star = ({ name }) => {
// if (name === "full") return <IconStar />
// if (name === "half") return <IconStarHalf />
// if (name === "empty") return <IconStar fill="var(--star-color-empty)" />
// return null
// }

// interface IStarInput {
//   num: number
//   starType: string
//   hoveredNum?: number
//   setHoveredNum: (num: number) => void
//   handleClick: (num: number) => void
// }
// const Star: React.FC<IStarInput> = ({ num, starType, hoveredNum, setHoveredNum, handleClick, ...props }) => {
//   const filled = starType === "filled" || hoveredNum >= num

//   return (
//     <span>
//       <IconFatStar
//         fill={filled ? "gold" : "white"}
//         stroke="#212121"
//         strokeWidth={1}
//         onMouseEnter={() => setHoveredNum(num)}
//         onMouseLeave={() => setHoveredNum(0)}
//         onClick={() => handleClick(num)}
//         style={{ cursor: "pointer" }}
//         {...props}
//       />
//       <Input
//         label={""}
//         name="stars"
//         type="hidden"
//         value={""}
//         // style={{ display: "none" }}
//         checked={starType === "filled"}
//         onChange={noop}
//       />
//     </span>
//   )
// }

const StarsSelect = styled.fieldset`
  border: none;
  display: inline-block;

  &:not(:checked) {
    & > input {
      position: absolute;
      top: -9999px;
      clip: rect(0, 0, 0, 0);
    }

    & > label {
      cursor: pointer;
      // &:before {
      //   content: "\f006  ";
      // }
      // float: right;
      // width: 1em;
      // padding: 0 0.05em;
      // overflow: hidden;
      // white-space: nowrap;
      // cursor: pointer;
      // font-size: 200%;
    }
  }

  & > input:checked {
    & ~ label {
      // &:before {
      //   content: "\f005  ";
      // }
    }
  }

  & > label:active {
    position: relative;
    top: 2px;
  }
`

interface IStar {
  starType?: "filled" | "empty"
  setHoveredNumber: (n: number) => void
  num: number
  name: string
  // register: (r: RefObject<HTMLInputElement>, reg?: RegisterOptions) => void
  register: any
}
const Star = ({ starType, setHoveredNumber, num, name, register }: IStar) => {
  const renderLabel = () => {
    if (starType === "filled")
      return <IconStar onMouseEnter={() => setHoveredNumber(num)} onMouseLeave={() => setHoveredNumber(0)} />
    if (starType === "empty")
      return (
        <IconStar
          onMouseEnter={() => setHoveredNumber(num)}
          onMouseLeave={() => setHoveredNumber(0)}
          fill="var(--star-color-empty)"
        />
      )
    return null
  }

  return (
    <>
      <input ref={register({ required: true })} type="radio" name={name} value={num} id={`${name}-${num}`} />
      <label htmlFor={`${name}-${num}`}>{renderLabel()}</label>
    </>
  )
}

interface IReactHookStarsInput {
  value: number
  name?: string
  max: number
  register: any
}
export default function ReactHookStarsInput({ value, name = "", register, max = 5 }: IReactHookStarsInput) {
  const [hoveredNumber, setHoveredNumber] = useState(0)

  const getStarType = (i: number) => {
    if (value > i) return "filled"
    if (hoveredNumber > i) return "filled"
    return "empty"
  }

  return (
    <>
      <StarsSelect>
        {new Array(max).fill(0).map((_, i) => (
          <Star
            name={name}
            key={i}
            num={i + 1}
            setHoveredNumber={setHoveredNumber}
            starType={getStarType(i)}
            register={register}
          />
        ))}
      </StarsSelect>
    </>
  )
}

// import { useState } from "react"
// import { IconFatStar, Input } from "components"
// import { noop } from "utils/misc"
// import styled from "styled-components"

// const StarsWrapper = styled.span`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   margin-top: 10px;

//   & i {
//     color: gold;
//   }
// `

// const StarsSpan = styled.span`
//   display: flex;
// `

// interface IStarInput {
//   num: number
//   starType: string
//   hoveredNum?: number
//   setHoveredNum: (num: number) => void
//   handleClick: (num: number) => void
// }
// const Star: React.FC<IStarInput> = ({ num, starType, hoveredNum, setHoveredNum, handleClick, ...props }) => {
//   const filled = starType === "filled" || hoveredNum >= num

//   return (
//     <span>
//       <IconFatStar
//         fill={filled ? "gold" : "white"}
//         stroke="#212121"
//         strokeWidth={1}
//         onMouseEnter={() => setHoveredNum(num)}
//         onMouseLeave={() => setHoveredNum(0)}
//         onClick={() => handleClick(num)}
//         style={{ cursor: "pointer" }}
//         {...props}
//       />
//       <Input
//         label={""}
//         name="stars"
//         type="hidden"
//         value={""}
//         // style={{ display: "none" }}
//         checked={starType === "filled"}
//         onChange={noop}
//       />
//     </span>
//   )
// }

// interface IStarsInput {
//   value?: number
//   handleClick: (num: any) => void
//   label?: string
//   labelStyles?: Record<string, any>
//   labelPrefix?: string
//   max?: number
// }
// const StarsInput: React.FC<IStarsInput> = ({
//   value,
//   handleClick,
//   label = "Select a rating",
//   labelStyles = {},
//   labelPrefix = "",
//   max = 5,
//   ...props
// }) => {
//   const amount = value ? value : 0
//   const [hoveredNum, setHoveredNum] = useState<number>(0)

//   const renderStars = () => {
//     return (
// <StarsSpan>
//   {new Array(max).fill(0).map((_, i) => (
//     <Star
//       key={i}
//       num={i + 1}
//       handleClick={handleClick}
//       hoveredNum={hoveredNum}
//       setHoveredNum={setHoveredNum}
//       starType={amount >= i + 1 ? "filled" : "empty"}
//     />
//   ))}
// </StarsSpan>
//     )
//   }

//   const renderValue = () => {
//     return (
//       <p style={labelStyles}>
//         {value ? (value === 1 ? `${labelPrefix} ${value} star` : `${labelPrefix} ${value} stars`) : label}
//       </p>
//     )
//   }

//   return (
//     <StarsWrapper {...props}>
//       {renderValue()}
//       {renderStars()}
//     </StarsWrapper>
//   )
// }

// export default StarsInput
