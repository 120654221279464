import * as R from "ramda"
import { useState } from "react"
import { IconStar } from "components"

import styled from "styled-components"

const StarsSelect = styled.fieldset`
  margin-top: 0.5rem;
  border: none;
  display: flex;
  align-items: center;

  & svg {
    cursor: pointer;
  }
`
const Rating = styled.span`
  font-size: 26px;
  margin-left: 0.5rem;
  transform: translateY(-2px);
`

interface IStar {
  starType?: "filled" | "empty"
  setHoveredNumber: (n: number) => void
  setValue: (v: number) => void
  num: number
  name: string
}
const Star = ({ setValue, starType, setHoveredNumber, num, name }: IStar) => {
  const renderLabel = () => {
    if (starType === "filled")
      return (
        <IconStar
          onMouseEnter={() => setHoveredNumber(num)}
          onMouseLeave={() => setHoveredNumber(0)}
          onClick={() => setValue(num)}
        />
      )
    if (starType === "empty")
      return (
        <IconStar
          onClick={() => setValue(num)}
          onMouseEnter={() => setHoveredNumber(num)}
          onMouseLeave={() => setHoveredNumber(0)}
          fill="var(--star-color-empty)"
        />
      )
    return null
  }

  return <span>{renderLabel()}</span>
}

interface IStarsInput {
  value: number
  setValue: (v: number) => void
  name?: string
  max: number
}
export default function StarsInput({ value, setValue, name = "", max = 5 }: IStarsInput) {
  const [hoveredNumber, setHoveredNumber] = useState(0)

  const getStarType = (i: number) => {
    if (value > i) return "filled"
    if (hoveredNumber > i) return "filled"
    return "empty"
  }

  return (
    <>
      <StarsSelect>
        {new Array(max).fill(0).map((_, i) => (
          <Star
            name={name}
            key={i}
            num={i + 1}
            setHoveredNumber={setHoveredNumber}
            starType={getStarType(i)}
            setValue={setValue}
            // something
          />
        ))}
        {R.isNil(value) ? null : (
          <Rating>
            {value} {value === 1 ? "star" : "stars"}
          </Rating>
        )}
      </StarsSelect>
    </>
  )
}
