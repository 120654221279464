import { Fragment } from "react"
import { IconStar, IconStarHalf, Spacer } from "components"
import { useCreateStars } from "hooks/ui/useCreateStars"
import styled from "styled-components"

const StarsWrapper = styled.div<{ $readOnly?: boolean; $size?: "sm" | "md" | "lg" }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  & svg {
    cursor: ${(props) => (props.$readOnly ? "initial" : "pointer")};
    ${(props) =>
      props.$size === "sm"
        ? `
      height: 24px;
      width: 24px;
    `
        : ``}
    ${(props) =>
      props.$size === "lg"
        ? `
      height: 48px;
      width: 48px;
    `
        : ``}
  }
`

const Star = ({ name }) => {
  if (name === "full") return <IconStar />
  if (name === "half") return <IconStarHalf />
  if (name === "empty") return <IconStar fill="var(--star-color-empty)" />
  return null
}

interface IStars {
  rating: number
  max?: number
  noLabel?: boolean
  epsilon?: number
  readOnly?: boolean
  className?: string
  size?: "sm" | "md" | "lg"
}
const Stars: React.FC<IStars> = ({ rating, max = 5, size = "md", epsilon = 0.2, readOnly = true, className = "" }) => {
  const createdStars = useCreateStars({ numStars: rating, max, epsilon })

  return (
    <StarsWrapper className={className} $readOnly={readOnly} $size={size}>
      {createdStars.map(({ pos, star }) => (
        <Fragment key={pos}>
          <Star name={star} />
          {/* {size === "sm" ? <Spacer size="xs" /> : null} */}
          {size === "md" ? <Spacer size="xs" /> : null}
          {size === "lg" ? <Spacer size="sm" /> : null}
        </Fragment>
      ))}
    </StarsWrapper>
  )
}

export default Stars
