import { IIcon as BaseIIcon } from "types/ui"

import {
  IconAppStore,
  IconBellSimple,
  IconBrowserParagraph,
  IconChevronRight,
  IconClose,
  IconCloseCircled,
  IconComments,
  IconDanger,
  IconEmail,
  IconFacebook,
  IconFileUpload,
  IconExhibition,
  IconGrid,
  IconHeart,
  IconLifeSaver,
  IconLogout,
  IconMobileContact,
  IconPreferences,
  IconSchool,
  IconSearch,
  IconSettings,
  IconShop,
  IconStar,
  IconStatistics,
  IconUserCircle,
} from "components/Icons"

const iconMapping = {
  app_store: IconAppStore,
  bell: IconBellSimple,
  browser_paragraph: IconBrowserParagraph,
  chevron_right: IconChevronRight,
  close: IconClose,
  close_circled: IconCloseCircled,
  comments: IconComments,
  danger: IconDanger,
  email: IconEmail,
  exhibit: IconExhibition,
  exhibition: IconExhibition,
  facebook: IconFacebook,
  file_upload: IconFileUpload,
  grid: IconGrid,
  heart: IconHeart,
  lifesaver: IconLifeSaver,
  logout: IconLogout,
  mobile_contact: IconMobileContact,
  preferences: IconPreferences,
  school: IconSchool,
  search: IconSearch,
  settings: IconSettings,
  shop: IconShop,
  statistics: IconStatistics,
  star: IconStar,
  user_circle: IconUserCircle,
}

interface IIcon extends BaseIIcon {
  iconName: keyof typeof iconMapping
}
const Icon: React.FC<IIcon> = ({ iconName = "search", ...props }) => {
  const SelectedIcon = iconMapping[iconName]

  return (
    <>
      <SelectedIcon {...props} />
    </>
  )
}

export default Icon
