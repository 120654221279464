import { Avatar, NextImage, ImgixPicture } from "components"
import styled from "styled-components"

const UserAvatarWrapper = styled.div``

const StyledNextImage = styled((props) => <NextImage pointer={false} {...props} />)``
const StyledImgix = styled((props) => <ImgixPicture {...props} />)``
const ImageWrapper = styled.div`
  max-height: 150px;
  max-width: 150px;
  min-height: 50px;
  min-width: 50px;
  position: relative;
`

export default function UserAvatar({ image, imgix_src, is_social, username, className = "", ...props }) {
  const defaultAvatarSrc = "vector_woman_avatar.png"
  const defaultImageElement = (
    <picture>
      <source srcSet={image} />
      <img src={require(`images/${defaultAvatarSrc}?webp`)} alt="AN" />
    </picture>
  )

  const imageElement = is_social ? (
    <ImageWrapper>
      <img src={image} alt={username} />
    </ImageWrapper>
  ) : (
    <ImageWrapper>
      {/* <StyledNextImage src={imgix_src} /> */}
      {image || imgix_src ? (
        <StyledImgix src={image} imgixSrc={imgix_src} isSocial={is_social} alt={username} />
      ) : (
        defaultImageElement
      )}
    </ImageWrapper>
  )

  return (
    <UserAvatarWrapper className={className}>
      <Avatar name={username} image={imageElement} {...props} onClick={() => {}} />
    </UserAvatarWrapper>
  )
}
