import styled from "styled-components"

const SmallRed = styled.small`
  color: var(--red-destructive);
  font-family: var(--font-primary);
  font-size: 14px;
`

const SmallRedText: React.FC = ({ children }) => (
  <SmallRed>
    <>{children}</>
  </SmallRed>
)

export default SmallRedText
