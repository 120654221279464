import { forwardRef, MouseEventHandler } from "react"
import styled from "styled-components"
import { noop } from "utils/misc"

type CardDirection = "horizontal" | "vertical"
type CardElevation = "flat" | "low" | "med" | "high" | "very-high" | "highest"

const CardWrapper = styled.div<{
  $elevation: CardElevation
  $hoverElevation?: CardElevation
  $noBorder?: boolean
  $square?: boolean
}>`
  display: flex;
  flex-direction: column;
  background: var(--card-background-color);
  border: ${(props) => (props.$noBorder ? "none" : `solid 1px var(--card-border-color)`)};
  border-radius: ${(props) => (props.$square ? "0" : `var(--card-border-radius)`)};
  box-shadow: ${(props) => `var(--card-elevation-${props.$elevation})`};
  transition: box-shadow 0.3s var(--juiced-up-ease);

  ${(props) =>
    props.$hoverElevation
      ? `
  cursor: pointer;
  &:hover {
    box-shadow: ${`var(--card-elevation-${props.$hoverElevation})`};
  }
  `
      : ``}
`
const CardContent = styled.div<{ $direction?: CardDirection; $noPadding?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.$direction === "horizontal" ? "row" : "column")};
  padding: ${(props) => (props.$noPadding ? 0 : `var(--card-padding)`)};
`

interface ICard extends React.ComponentPropsWithoutRef<"div"> {
  hoverElevation?: CardElevation
  direction?: CardDirection
  elevation?: CardElevation
  // onMouseOver?: (e?: MouseEventHandler<HTMLDivElement>) => void
  // onMouseLeave?: (e?: MouseEventHandler<HTMLDivElement>) => void
  wrapContent?: boolean
  noPadding?: boolean
  noBorder?: boolean
  square?: boolean
  className?: string
}
const Card = forwardRef<HTMLDivElement, ICard>(
  (
    {
      hoverElevation,
      direction = "vertical",
      elevation = "flat",
      wrapContent = false,
      noPadding = false,
      noBorder = false,
      square = false,
      className = "",
      children,
      ...props
    },
    ref
  ) => {
    const renderContent = () => {
      if (wrapContent) {
        return (
          <CardContent $direction={direction} $noPadding={noPadding} className="card-content">
            <>{children}</>
          </CardContent>
        )
      }

      return <>{children}</>
    }

    return (
      <CardWrapper
        ref={ref}
        $elevation={elevation}
        $hoverElevation={hoverElevation}
        $noBorder={noBorder}
        $square={square}
        className={className}
        {...props}
      >
        {renderContent()}
      </CardWrapper>
    )
  }
)

export default Card
