import { useEffect, useState } from "react"
import styled from "styled-components"
import { SpinnerSize, SpinnerType } from "types/ui"

// type SpinnerSize = "sm" | "md" | "lg" | "full-size"
// type SpinnerType = "primary" | "secondary" | "accent"

const SpinnerContainer = styled.div<{ $size: SpinnerSize }>`
  width: ${(props) => `var(--spinner-container-width-${props.$size})`};
  height: ${(props) => `var(--spinner-container-height-${props.$size})`};
  border-radius: 100%;
  position: relative;
  margin: 0 auto;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
const LoadingIndicator = styled.div<{ $size: SpinnerSize; $type: SpinnerType }>`
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: ${(props) => `var(--spinner-section-size-${props.$size})`} solid transparent;
    border-top-color: ${(props) => `var(--spinner-color-${props.$type})`};
    z-index: var(--spinner-section-z-index);
    animation: spin 1s infinite;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-top-color: ${(props) => `var(--spinner-color-${props.$type})`};
    border: ${(props) => `var(--spinner-section-size-${props.$size})`} solid var(--spinner-color-background);
  }
`

const spinnerTypes: SpinnerType[] = ["primary", "secondary", "accent"]

interface ISpinner {
  size?: SpinnerSize
  type?: SpinnerType
  rotateColors?: boolean
}
export default function Spinner({ size = "md", type = "primary", rotateColors = false }: ISpinner) {
  const [spinnerType, setSpinnerType] = useState<SpinnerType>(type)

  useEffect(() => {
    let rotateColorInterval
    if (rotateColors) {
      rotateColorInterval = setInterval(() => {
        setSpinnerType((spinnerType) => {
          const idx = spinnerTypes.indexOf(spinnerType)
          const newIdx = idx === spinnerTypes.length - 1 ? 0 : idx + 1
          return spinnerTypes[newIdx]
        })
      }, 1000)
    }

    return () => {
      if (rotateColors) {
        clearInterval(rotateColorInterval)
      }
    }
  }, [])

  return (
    <SpinnerContainer $size={size}>
      <LoadingIndicator $size={size} $type={spinnerType} />
    </SpinnerContainer>
  )
}
