import styled from "styled-components"

const NotFoundWrapper = styled.div``

const StyledH1 = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0 1rem 0;
  max-width: 100%;
`
const StyledSVG = styled((props) => <img {...props} />)`
  margin: 50px 5%;
  min-height: 500px;
  height: 70%;
  max-height: 80vh;
  max-width: 90%;
`

export default function NotFound({ resource = "" }) {
  return (
    <NotFoundWrapper>
      <StyledH1>Oops! {resource} not found.</StyledH1>
      <StyledSVG src={"/assets/images/undraw_not_found.svg"} />
    </NotFoundWrapper>
  )
}
