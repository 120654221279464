import { motion, AnimateSharedLayout } from "framer-motion"
import styled from "styled-components"

const TabsWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & li {
    position: relative;
    padding-bottom: 2px;
  }

  & .underline {
    width: 100%;
    height: 3px;
    border-radius: 4px;
    position: absolute;
    background: var(--color-primary-a50);
  }
`

const StyledTab = styled.button<{ $isSelected?: boolean }>`
  outline: none;
  border: none;
  background: var(--pure-white);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2.15rem;
  border-bottom: solid 2px transparent;
  transition: all 0.2s ease;

  ${(props) =>
    props.$isSelected
      ? `
    color: var(--color-primary);
  `
      : `
     color: var(--font-dark); 
  `}
`

const Tab = ({ handleOnSelect, label, isSelected }) => {
  return (
    <StyledTab onClick={handleOnSelect} $isSelected={isSelected}>
      {label}
    </StyledTab>
  )
}

interface Tabs {
  handleOnSelect: (v: any) => void
  selectedTab?: any
  options: Array<{ value: any; label: string }>
  className?: string
}
export default function Tabs({ options, selectedTab, handleOnSelect, className = "" }: Tabs) {
  return (
    <TabsWrapper className={className}>
      <AnimateSharedLayout>
        {options.map(({ value, label }) => (
          <motion.li key={value}>
            <Tab handleOnSelect={() => handleOnSelect(value)} label={label} isSelected={selectedTab === value} />
            {selectedTab === value && <motion.div className="underline" layoutId="underline" />}
          </motion.li>
        ))}
      </AnimateSharedLayout>
    </TabsWrapper>
  )
}
