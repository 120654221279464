import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Logout: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#605DEC",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeLinejoin = "miter",
  strokeMiterlimit = 10,
  className = "",
  ...props
}) => (
  <IconWrapper size={size} {...props} className={className}>
    <g strokeWidth={2} transform="translate(0, 0)">
      <line
        x1="36"
        y1="24"
        x2="6"
        y2="24"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={"butt"}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></line>
      <polyline
        points="16 34 6 24 16 14"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></polyline>
      <path
        d="M18,2H38a4,4,0,0,1,4,4V42a4,4,0,0,1-4,4H18"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
      ></path>
    </g>
  </IconWrapper>
)

export default Logout
