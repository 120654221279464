import styled from "styled-components"
import { BadgeType } from "types/ui"
import { noop } from "utils/misc"

const BadgeWrapper = styled.div<{ $badgeType?: BadgeType }>`
  // width: var(--badge-width);
  height: var(--badge-height);
  border-radius: var(--badge-border-radius);
  padding: var(--badge-padding);
  display: inline-block;
  background: var(--badge-color-${(props) => props.$badgeType});
`
const BadgeText = styled.p<{ $badgeType?: BadgeType }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  color: var(--badge-font-color-${(props) => props.$badgeType});
  font-size: var(--badge-font-size);
`

interface IBadge {
  badgeType: BadgeType
  text: string
  className?: string
  onClick?: () => void
}
export default function Badge({ badgeType = BadgeType.SECONDARY, text, className = "", onClick = noop }: IBadge) {
  return (
    <BadgeWrapper $badgeType={badgeType} className={className} onClick={onClick}>
      <BadgeText $badgeType={badgeType}>
        <>{text}</>
      </BadgeText>
    </BadgeWrapper>
  )
}
