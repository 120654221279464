import styled from "styled-components"
// import { useSession } from "next-auth/client"
import { useAuthedUserProfile } from "hooks/profiles/useAuthedUserProfile"
import { Avatar, Icon, Button, List, NotificationDot, UserAvatar } from "components"
import { noop } from "utils/misc"
import media from "utils/mediaQueryTemplates"

// import { User } from "types/auth"
import { ButtonType, ButtonSize } from "types/ui"

const NavbarLinksWrapper = styled.div`
  height: calc(var(--navbar-height) - 2px);
  display: flex;
  align-items: center;
  min-width: 150px;

  ${media.small`
    min-width: 250px;
  `}
`

// const StyledPicture = styled.picture``

const NavLinkItemWrapper = styled.div<{ $isAuthButton?: boolean }>`
  flex: ${(props) => (props.$isAuthButton ? 2 : 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--navbar-height) - 2px);
  cursor: pointer;
  position: relative;

  &:hover {
    background: var(--color-primary-lightest);
  }

  &.bell-icon {
    display: none;
  }

  ${media.small`
    &.bell-icon {
      display: flex;
    }
  `}
`
const StyledIcon = styled((props) => <Icon {...props} />)`
  height: 42px;
  width: 42px;

  ${media.small`
    height: 48px;
    width: 48px;  
  `}
`
const StyledNotificationDot = styled((props) => <NotificationDot {...props} />)`
  position: absolute;
  top: 5px;
  right: 5px;
  top: 25%;
  top: 21px;
  z-index: var(--z-index-navbar-dropdown);
`

interface NavItem {
  id: string
  type: ButtonType
  size: ButtonSize
}

const defaultNavItems: NavItem[] = [
  {
    id: "bell",
    type: "plain",
    size: "lg",
  },
  {
    id: "grid",
    type: "plain",
    size: "lg",
  },
  {
    id: "auth",
    type: "primary",
    size: "xl",
  },
]

const NavLinkBellIcon = ({ toggleMenu, hasNotifications }) => {
  return (
    <NavLinkItemWrapper onClick={() => toggleMenu("bell")} className="bell-icon">
      <StyledIcon iconName="bell" strokeWidth={1.5} />
      {hasNotifications ? <StyledNotificationDot type="accent" /> : null}
    </NavLinkItemWrapper>
  )
}
const NavLinkGridIcon = ({ toggleMenu }) => {
  return (
    <NavLinkItemWrapper onClick={() => toggleMenu("grid")}>
      <StyledIcon iconName="grid" />
    </NavLinkItemWrapper>
  )
}
const NavLinkAuthButton = ({ navItem, toggleMenu }) => {
  return (
    <NavLinkItemWrapper $isAuthButton>
      <Button buttonSize={navItem.size} buttonType={navItem.type} linkTo={"/authenticate/"}>
        Sign In
      </Button>
    </NavLinkItemWrapper>
  )
}
const NavLinkAvatar = ({ user, navItem, toggleMenu, onClick = noop }) => {
  const defaultAvatarSrc = "vector_woman_avatar.png"
  const imageElement = (
    <picture>
      <source srcSet={user.image} />
      <img src={require(`images/${defaultAvatarSrc}?webp`)} alt="AN" />
    </picture>
  )
  return (
    <NavLinkItemWrapper onClick={onClick}>
      {/* <Avatar avatarSize={"md"} name={user?.username} image={user?.image ? imageElement : null} /> */}
      <UserAvatar
        className="pointer"
        image={user?.image}
        imgix_src={user?.imgix_src}
        name={user?.username}
        username={user?.username}
        is_social={user.is_social}
      />
    </NavLinkItemWrapper>
  )
}

interface INavLink {
  // user?: User
  user?: any
  hasNotifications?: boolean
  navItem?: NavItem
  toggleMenu?: (label: string) => void
  isSessionLoading?: boolean
}
const NavLink: React.FC<INavLink> = ({ user, hasNotifications, navItem, toggleMenu, isSessionLoading }) => {
  if (navItem.id === "bell") return <NavLinkBellIcon hasNotifications={hasNotifications} toggleMenu={toggleMenu} />
  if (navItem.id === "grid") return <NavLinkGridIcon toggleMenu={toggleMenu} />
  if (navItem.id === "auth") {
    // if (isSessionLoading) return <NavLinkAvatar toggleMenu={toggleMenu} navItem={navItem} user={{}} />
    return user ? (
      <NavLinkAvatar toggleMenu={toggleMenu} navItem={navItem} user={user} onClick={() => toggleMenu("auth")} />
    ) : (
      <NavLinkAuthButton toggleMenu={toggleMenu} navItem={navItem} />
    )
  }

  return null
}

interface INavbarLinks {
  notifications?: Array<any>
  navItems?: NavItem[]
  toggleMenu?: (label: string) => void
}
const NavbarLinks: React.FC<INavbarLinks> = ({ notifications, navItems = defaultNavItems, toggleMenu }) => {
  const { userProfile, isSessionLoading } = useAuthedUserProfile()

  return (
    <NavbarLinksWrapper>
      <List items={navItems} direction="horizontal">
        {(item) => (
          <NavLink
            key={item.id}
            hasNotifications={Boolean(notifications?.length)}
            navItem={item}
            user={userProfile?.email ? userProfile : null}
            toggleMenu={toggleMenu}
            isSessionLoading={isSessionLoading}
          />
        )}
      </List>
    </NavbarLinksWrapper>
  )
}

export default NavbarLinks
