import styled from "styled-components"
import { noop } from "utils/misc"
import { ChipType, ChipSize } from "types/ui"
import { isDefined } from "utils/parseUtils"

const ChipWrapper = styled.div<{ $chipType: ChipType; $chipSize: ChipSize; $isClickable?: boolean }>`
  border-radius: var(--chip-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--chip-font-color);

  ${(props) => `
    background: var(--chip-background-color-${props.$chipType});
    padding: var(--chip-padding-${props.$chipSize});
    font-size: var(--chip-font-size-${props.$chipSize});
    cursor: ${props.$isClickable ? "pointer" : "initial"};
  `}
`

interface IChip {
  text: string
  chipType: ChipType
  chipSize: ChipSize
  onClick?: () => void
  className?: string
}

const Chip: React.FC<IChip> = ({ text, chipType, chipSize, onClick, className = "" }) => {
  const handleOnChipClick = () => {
    if (onClick) onClick()

    noop()
  }

  return (
    <ChipWrapper
      onClick={handleOnChipClick}
      $chipType={chipType}
      $chipSize={chipSize}
      $isClickable={isDefined(onClick)}
      className={className}
    >
      <p>{String(text)}</p>
    </ChipWrapper>
  )
}

export default Chip
