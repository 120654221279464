import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"
import { noop } from "utils/misc"

const Settings: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  strokeGear,
  fillGear,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  onClick = noop,
  ...props
}) => {
  return (
    <IconWrapper size={size} onClick={onClick} {...props}>
      <circle
        cx="24"
        cy="24"
        r="7"
        fill={fill}
        stroke={strokeGear || stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
        strokeLinejoin={strokeLinejoin}
      />{" "}
      <path
        d="M46,24h0a3.26,3.26,0,0,0-2.99-3.249L39.6,20.466a15.89,15.89,0,0,0-2.072-4.991l2.217-2.62a3.26,3.26,0,0,0-.183-4.411h0a3.26,3.26,0,0,0-4.411-.183l-2.62,2.217A15.876,15.876,0,0,0,27.534,8.4L27.249,4.99A3.26,3.26,0,0,0,24,2h0a3.26,3.26,0,0,0-3.249,2.99L20.466,8.4a15.89,15.89,0,0,0-4.991,2.072L12.855,8.26a3.26,3.26,0,0,0-4.411.183h0a3.26,3.26,0,0,0-.183,4.411l2.217,2.62A15.876,15.876,0,0,0,8.4,20.466l-3.415.285A3.26,3.26,0,0,0,2,24H2a3.26,3.26,0,0,0,2.99,3.249l3.415.285a15.89,15.89,0,0,0,2.072,4.991L8.26,35.145a3.26,3.26,0,0,0,.183,4.411h0a3.26,3.26,0,0,0,4.411.183l2.62-2.217A15.876,15.876,0,0,0,20.466,39.6l.285,3.415A3.26,3.26,0,0,0,24,46h0a3.26,3.26,0,0,0,3.249-2.99l.285-3.415a15.89,15.89,0,0,0,4.991-2.072l2.62,2.217a3.26,3.26,0,0,0,4.411-.183h0a3.26,3.26,0,0,0,.183-4.411l-2.217-2.62A15.876,15.876,0,0,0,39.6,27.534l3.415-.285A3.26,3.26,0,0,0,46,24Z"
        fill={fillGear || fill}
        stroke={strokeGear || stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
    </IconWrapper>
  )
}

export default Settings
