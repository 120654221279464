import { useDispatch } from "react-redux"
import { useTypedSelector } from "models/redux"
import { Actions as uiActions } from "models/ui"
import { useWindowScrollPosition } from "hooks/ui/useWindowScrollPosition"

export const useNavbar = () => {
  const dispatch = useDispatch()
  const { y } = useWindowScrollPosition({ throttleMs: 200 })

  const menuOpen = useTypedSelector((state) => state.ui.dropdownMenuOpen)

  const handleDropdownLabelClick = (label: string) => dispatch(uiActions.handleDropdownLabelClick(label))
  const closeDropdownMenu = (label: string) => dispatch(uiActions.closeSingleDropdown(label))
  const navigateInternal = (path: string) => dispatch(uiActions.navigateInternal({ path }))

  return { yPos: y, menuOpen, handleDropdownLabelClick, closeDropdownMenu, navigateInternal }
}
