import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSVG = styled.svg``

export default function Info({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  // strokeLinejoin = "miter",
  className,
}: IIcon) {
  return (
    <StyledSVG
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <circle />
      <line
        x1="24"
        y1="20"
        x2="24"
        y2="40"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
      <circle cx="24" cy="10" r={strokeWidth} fill={stroke} />
      <circle
        cx="24"
        cy="24"
        r="23"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      />
    </StyledSVG>
  )
}
