import { timeDay as d3TimeDay, timeMinute as d3TimeMinute } from "d3-time"
import { timeFormat as d3TimeFormat, isoFormat as d3IsoFormat, timeParse as d3TimeParse } from "d3-time-format"

export const formatFullYear = d3TimeFormat("%Y")
export const parseSlashedDateString = (d: string) => d3TimeParse("%d/%m/%Y")(d)
export const formatDateShortRoundedUp = (d: Date) => d3TimeFormat("%b %d, %Y")(d3TimeDay.ceil(d))
export const formatDateRoundedUp = (d: Date) => d3TimeFormat("%B %d, %Y")(d3TimeDay.ceil(d))
export const formatDateShort = d3TimeFormat("%b %d, %Y")
export const formatDate = d3TimeFormat("%B %d, %Y")
export const formatTime = d3TimeFormat("%H:%M")
export const formatDateTime = d3TimeFormat("%B %d, %Y %H:%M")
export const formatDateTimeWithTz = d3TimeFormat("%a, %b %d, %Y at %I:%M %p")
export const formatDateIsoFormat = (d: Date) => d3IsoFormat(d)
// inputs expect datetime-local to be formatted as "yyyy-MM-ddThh:mm"
const formatYearForInput = d3TimeFormat(`%Y-%m-%d`)
const formatTimeForInput = d3TimeFormat(`%H:%M`)
export const formatDateTimeForInput = (datetime: Date) =>
  `${formatYearForInput(datetime)}T${formatTimeForInput(datetime)}`

export const getMinutesDifferenceBetweenTwoDates = (d1: Date, d2: Date): number => {
  return d3TimeMinute.count(d1, d2)
}

export const dateIsBeforeOtherDate = (d1: Date, d2: Date): boolean => {
  return d3TimeMinute.count(d1, d2) > 0
}

export function isDateObject(val: any): val is Date {
  return (
    val instanceof Date && // check if date instance
    typeof val.getMonth === "function" && // check if appropriate methods available
    Object.prototype.toString.call(val) === "[object Date]" // check toString prototype
  )
}
