import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Comment: React.FC<IIcon> = ({
  size = 64,
  fill = "none",
  fillFirstComment,
  fillSecondComment,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <path
          fill="#444444"
          d="M63,28C63,14,49.4,3,32,3S1,14,1,28c0,14.1,13.9,25.5,31,25.5c2.8,0,5.6-0.3,8.3-1l15.3,6.3
	C55.7,59,55.9,59,56,59c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.5,0.4-0.9l-1-13.8C60.5,39.6,63,33.9,63,28z M34,35H18
	c-0.6,0-1-0.4-1-1s0.4-1,1-1h16c0.6,0,1,0.4,1,1S34.6,35,34,35z M46,23H18c-0.6,0-1-0.4-1-1s0.4-1,1-1h28c0.6,0,1,0.4,1,1
	S46.6,23,46,23z"
          // stroke={stroke}
          // strokeLinecap={strokeLinecap}
          // strokeMiterlimit={strokeMiterlimit}
          // strokeWidth={strokeWidth}
          // strokeLinejoin={strokeLinejoin}
        ></path>

        {/* <g transform="translate(0, 0)">
            <path
              fill="#444444"
              d="M63,28C63,14,49.4,3,32,3S1,14,1,28c0,14.1,13.9,25.5,31,25.5c2.8,0,5.6-0.3,8.3-1l15.3,6.3
	C55.7,59,55.9,59,56,59c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.5,0.4-0.9l-1-13.8C60.5,39.6,63,33.9,63,28z M34,35H18
	c-0.6,0-1-0.4-1-1s0.4-1,1-1h16c0.6,0,1,0.4,1,1S34.6,35,34,35z M46,23H18c-0.6,0-1-0.4-1-1s0.4-1,1-1h28c0.6,0,1,0.4,1,1
	S46.6,23,46,23z"
            ></path>
          </g> */}

        {/* <path
          d="M38,11h4a4,4,0,0,1,4,4V32a4,4,0,0,1-4,4H38v8L26,36H22"
          fill={fillSecondComment || fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M34,3H6A4,4,0,0,0,2,7V24a4,4,0,0,0,4,4h4V38L24,28H34a4,4,0,0,0,4-4V7A4,4,0,0,0,34,3Z"
          fill={fillFirstComment || fillSecondComment ? "var(--pure-white)" : fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></path> */}
      </g>
    </IconWrapper>
  )
}

export default Comment
