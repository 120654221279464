import styled from "styled-components"
import { NotificationDotSize, NotificationDotType } from "types/ui"

const StyledDot = styled.div<{ $type: string; $size?: string }>`
  height: 16px;
  width: 16px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${(props) => `var(--notification-dot-color-${props.$type})`};
  border: solid var(--notification-dot-border-size) var(--notification-dot-border-color);
`

interface INotificationDot {
  type?: NotificationDotType
  size?: NotificationDotSize
  className?: string
}
const NotificationDot: React.FC<INotificationDot> = ({ type = "accent", size = "md", className = "" }) => {
  return (
    <>
      <StyledDot $type={type} $size={size} className={className} />
    </>
  )
}

export default NotificationDot
