import styled from "styled-components"
import Image from "next/image"
import { noop } from "utils/misc"

// const myLoader = ({ src, width, quality }) => {
//   return `https://example.com/${src}?w=${width}&q=${quality || 75}`
// }

// const MyImage = (props) => {
//   return (
//     <Image
//       loader={myLoader}
//       src="/me.png"
//       alt="Picture of the author"
//       width={500}
//       height={500}
//     />
//   )
// }
const StyledNextImage = styled((props) => <Image {...props} />)``
const StyledImage = styled.img`
  font-size: 15px;
  color: black;
`

const VALID_LAYOUT_VALUES = ["fill", "fixed", "intrinsic", "responsive", undefined] as const
type LayoutValue = typeof VALID_LAYOUT_VALUES[number]

type ImgElementStyle = NonNullable<JSX.IntrinsicElements["img"]["style"]>

interface ImgixPictureProps {
  src: string
  imgixSrc?: string
  // srcSet?: string
  isSocial?: boolean
  layout?: LayoutValue
  objectFit?: ImgElementStyle["objectFit"]
  objectPosition?: ImgElementStyle["objectPosition"]
  // sizes?: string
  alt?: string
  onClick?: () => void
  className?: string
}
const ImgixPicture: React.FC<ImgixPictureProps> = ({
  src,
  imgixSrc,
  // srcSet,
  isSocial = false,
  layout = "fill",
  objectFit = "cover",
  objectPosition = "left top",
  // sizes = "100vw",
  alt = "imgix",
  onClick = noop,
  className = "",
  ...props
}) => {
  // // if imgix picture has srcSet and not a social account
  // if (!isSocial && srcSet) {
  //   return <StyledImage src={src} sizes={sizes} srcSet={srcSet} onClick={onClick} className={className} {...props} />
  // }

  if (!isSocial && imgixSrc) {
    return (
      <StyledNextImage
        src={imgixSrc}
        layout={layout}
        objectFit={objectFit}
        objectPosition={objectPosition}
        onClick={onClick}
        className={className}
        {...props}
      />
    )
  }

  return src ? <StyledImage src={src} alt={alt} onClick={onClick} className={className} {...props} /> : null
}

export default ImgixPicture

// import styled from "styled-components"

// const StyledImage = styled.img`
//   font-size: 15px;
//   color: black;
// `

// interface ImgixPictureProps {
//   src: string
//   imgixSrc?: string
//   srcSet?: string
//   isSocial?: boolean
//   sizes?: string
//   alt?: string
//   onClick?: () => void
//   className?: string
// }
// const ImgixPicture: React.FC<ImgixPictureProps> = ({
//   src,
//   imgixSrc,
//   srcSet,
//   isSocial = false,
//   sizes = "100vw",
//   alt = "imgix",
//   onClick = () => {},
//   className = "",
//   ...props
// }) => {
//   // if imgix picture has srcSet and not a social account
//   if (!isSocial && srcSet) {
//     return <StyledImage src={src} sizes={sizes} srcSet={srcSet} onClick={onClick} className={className} {...props} />
//   }

//   if (!isSocial && imgixSrc) {
//     return <StyledImage src={imgixSrc} sizes={sizes} onClick={onClick} className={className} {...props} />
//   }

//   return src ? <StyledImage src={src} alt={alt} onClick={onClick} className={className} {...props} /> : null
// }

// export default ImgixPicture
