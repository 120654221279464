import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Lifesaver: React.FC<IIcon> = ({
  size = 64,
  fill = "#444",
  stroke = "none",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <path
          stroke={stroke}
          d="M4,19a1,1,0,0,1-1-1A15.017,15.017,0,0,1,18,3a1,1,0,0,1,0,2A13.015,13.015,0,0,0,5,18,1,1,0,0,1,4,19Z"
          fill={fill}
        ></path>
        <path
          stroke={stroke}
          d="M60,19a1,1,0,0,1-1-1A13.015,13.015,0,0,0,46,5a1,1,0,0,1,0-2A15.017,15.017,0,0,1,61,18,1,1,0,0,1,60,19Z"
          fill={fill}
        ></path>
        <path
          stroke={stroke}
          d="M46,61a1,1,0,0,1,0-2A13.015,13.015,0,0,0,59,46a1,1,0,0,1,2,0A15.017,15.017,0,0,1,46,61Z"
          fill={fill}
        ></path>
        <path
          stroke={stroke}
          d="M18,61A15.017,15.017,0,0,1,3,46a1,1,0,0,1,2,0A13.015,13.015,0,0,0,18,59a1,1,0,0,1,0,2Z"
          fill={fill}
        ></path>
        <path
          stroke={stroke}
          d="M32,5A27,27,0,1,0,59,32,27.03,27.03,0,0,0,32,5ZM44,32A12,12,0,1,1,32,20,12.013,12.013,0,0,1,44,32ZM27,7.5a25.1,25.1,0,0,1,10,0V18.939a13.782,13.782,0,0,0-10,0ZM7,32a25.042,25.042,0,0,1,.5-5H18.939a13.782,13.782,0,0,0,0,10H7.5A25.042,25.042,0,0,1,7,32ZM27,56.477V45.066a13.85,13.85,0,0,0,10,0V56.477a24.162,24.162,0,0,1-10,0ZM45.061,37a13.782,13.782,0,0,0,0-10H56.5a25.1,25.1,0,0,1,0,10Z"
          fill={fill}
        ></path>
      </g>
    </IconWrapper>
  )
}

export default Lifesaver
