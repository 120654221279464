import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const NewConstruction: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <polyline
        points="11 45 3 45 3 25 6 25"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></polyline>
      <line
        x1="17"
        y1="31"
        x2="21"
        y2="31"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="17"
        y1="37"
        x2="21"
        y2="37"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="17"
        y1="25"
        x2="21"
        y2="25"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <polyline
        points="23 12 23 3 45 9 45 45 11 45 11 17 27 17 27 45"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></polyline>
      <line
        x1="33"
        y1="37"
        x2="33"
        y2="15"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="39"
        y1="37"
        x2="39"
        y2="17"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
    </IconWrapper>
  )
}

export default NewConstruction
