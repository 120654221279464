/**
 * Ensures a username consists of only letters, numbers, underscores, and dashes
 *
 * @param {String} username - username to be validated
 * @return {Boolean}
 */
export function validateUsername(username: string): boolean {
  return /^[a-zA-Z0-9_-]+$/.test(username)
}

export const isValidEmail = (email: string): boolean => {
  const atSignIdx = email.indexOf("@")
  if (atSignIdx === -1) {
    return false
  }

  // ensure at least one "." after "@" sign
  if (email.slice(atSignIdx).indexOf(".") === -1) {
    return false
  }

  return true
}

export const validateEmailRegex = (email: string): boolean => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false
  }

  return true
}

export const validatePasswordLength = (password: string): boolean => {
  if (password.length < 7) {
    return false
  }

  return true
}

export const validateBusinessEin = (ein: string): boolean => {
  if (!/^\d{2}\-?\d{7}$/.test(ein)) return false // eslint-disable-line

  return true
}

/**
 * Given any string, returns the string in US phone number format:
 * `(###) ###-####`
 */
export function formatPhoneNumberForUS(str: string): string {
  // Replace all non-digit chars
  const str2 = ("" + str).replace(/\D/g, "")
  // Extract matching parts
  const matches = str2.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/)
  if (!matches) return ""
  if (matches[3]) return `(${matches[1]}) ${matches[2]}-${matches[3]}`
  if (matches[2]) return `(${matches[1]}) ${matches[2]}`
  return `(${matches[1]}`
}

// export function formatPhoneNumberForStripeProvider(phoneNumber) {
//   if (!phoneNumber || typeof phoneNumber !== "string") return phoneNumber
//   return phoneNumber.trim().replace("(", "").replace(")", "").replace(" ", "-")
// }

export function formatPhoneNumberForStripeProvider(phoneNumber: string): string {
  if (!phoneNumber || typeof phoneNumber !== "string") return phoneNumber
  return phoneNumber.trim().replace("(", "").replace(")", "").replace(/\s/g, "").replace(/-/g, "")
}

/* example taken from redux form https://redux-form.com/8.3.0/examples/wizard/ */
export const validate = (values: Record<string, any>): Record<string, string> => {
  const errors: Record<string, string> = {}

  if (!values.firstName) {
    errors.firstName = "Required"
  }
  if (!values.lastName) {
    errors.lastName = "Required"
  }
  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }
  if (!values.sex) {
    errors.sex = "Required"
  }
  if (!values.favoriteColor) {
    errors.favoriteColor = "Required"
  }
  return errors
}

/**
 * From segmentio.io 'is-url' package found here: https://github.com/segmentio/is-url/blob/master/index.js
 *
 * RegExps.
 * A URL must match #1 and then at least one of #2/#3.
 * Use two levels of REs to avoid REDOS.
 */

// eslint-disable-line
const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/
const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/ // eslint-disable-line
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/ // eslint-disable-line

export function isUrl(str: string): boolean {
  if (typeof str !== "string") {
    return false
  }

  const match = str.match(protocolAndDomainRE)
  if (!match) {
    return false
  }

  const everythingAfterProtocol = match[1]
  if (!everythingAfterProtocol) {
    return false
  }

  if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true
  }

  return false
}

// const fullFormatter = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   minimumFractionDigits: 2,
// })
const purgePrice = (price: any): string => {
  let formattedPrice = ""
  String(price)
    .split("")
    .forEach((char) => {
      if (!Number.isNaN(Number(char)) || char === ".") {
        // return (formattedPrice += char)
        formattedPrice += char
      }
    })
  return formattedPrice
}
export const formatCurrency = (price: any): string => {
  const splitPrice = purgePrice(price).split(".")
  let dollars = splitPrice[0]
  let cents = splitPrice?.[1]
  if (cents?.length > 2) {
    cents = cents.slice(0, 2)
  }
  if (splitPrice.length === 2) {
    return `${dollars}.${cents}`
  }
  return splitPrice[0]
}
