import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const MobileContact: React.FC<IIcon> = ({
  size = 32,
  fill = "none",
  fillFirst,
  fillSecond,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  className = "",
  ...props
}) => {
  return (
    <IconWrapper size={size} className={className} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0.5, 0.5)">
        <path
          d="M24.865,16.814a6.976,6.976,0,0,0,0-7.628"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M21.34,14.856a2.94,2.94,0,0,0,0-3.712"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <path
          d="M1,25V7A2,2,0,0,1,3,5H29a2,2,0,0,1,2,2V25a2,2,0,0,1-2,2H3A2,2,0,0,1,1,25Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></path>
        <line
          x1="6"
          y1="22"
          x2="18"
          y2="22"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></line>
        <line
          x1="22"
          y1="22"
          x2="26"
          y2="22"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></line>
        <rect
          x="6"
          y="10"
          width="5"
          height="4"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></rect>
        <circle
          cx="18"
          cy="13"
          r="1"
          fill={stroke}
          stroke={stroke}
          strokeLinecap="butt"
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={1}
          strokeLinejoin={strokeLinejoin}
        ></circle>
      </g>
    </IconWrapper>
  )
}

export default MobileContact
