// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g transform="translate(0, 0)"><path data-color="color-2" d="M31.789,10.386l-7-9a1.035,1.035,0,0,0-1.578,0l-7,9A1,1,0,0,0,17,12h5v9a2,2,0,0,0,4,0V12h5a1,1,0,0,0,.789-1.614Z" fill="#444444"></path><path d="M46.937,27.649l-6-16A1,1,0,0,0,40,11H36a1,1,0,0,0,0,2h3.307l5.25,14H32a1,1,0,0,0-1,1v5H17V28a1,1,0,0,0-1-1H3.443l5.25-14H12a1,1,0,0,0,0-2H8a1,1,0,0,0-.937.649l-6,16L1,28V43a2,2,0,0,0,2,2H45a2,2,0,0,0,2-2V28Z" fill="#444444"></path></g></svg>

import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSvg = styled.svg``

export default function FileUpload({
  stroke = "#6E7491",
  fill = "#6E7491",
  className = "",
  strokeWidth = 2,
  strokeLinecap = "round",
  strokeLinejoin = "round",
}: IIcon) {
  return (
    <StyledSvg className={className} x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48">
      <g transform="translate(0, 0)">
        <path
          d="M31.789,10.386l-7-9a1.035,1.035,0,0,0-1.578,0l-7,9A1,1,0,0,0,17,12h5v9a2,2,0,0,0,4,0V12h5a1,1,0,0,0,.789-1.614Z"
          // fill="#444444"
          fill={fill}
        ></path>
        <path
          d="M46.937,27.649l-6-16A1,1,0,0,0,40,11H36a1,1,0,0,0,0,2h3.307l5.25,14H32a1,1,0,0,0-1,1v5H17V28a1,1,0,0,0-1-1H3.443l5.25-14H12a1,1,0,0,0,0-2H8a1,1,0,0,0-.937.649l-6,16L1,28V43a2,2,0,0,0,2,2H45a2,2,0,0,0,2-2V28Z"
          // fill="#444444"
          fill={fill}
        ></path>
      </g>
    </StyledSvg>
  )
}
