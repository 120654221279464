import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Statistics: React.FC<IIcon> = ({
  size = 64,
  fill = "none",
  fillFirst,
  fillSecond,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "butt",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <rect
          x="4"
          y="44"
          width="8"
          height="13"
          fill={fillFirst || fill}
          stroke={stroke}
          strokeLinecap={"square"}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></rect>
        <rect
          x="36"
          y="43"
          width="8"
          height="14"
          fill={fillFirst || fill}
          stroke={stroke}
          strokeLinecap={"square"}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></rect>
        <rect
          x="20"
          y="27"
          width="8"
          height="30"
          fill={fillSecond || fill}
          stroke={stroke}
          strokeLinecap={"square"}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></rect>
        <rect
          x="52"
          y="27"
          width="8"
          height="30"
          fill={fillSecond || fill}
          stroke={stroke}
          strokeLinecap={"square"}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></rect>
        <circle
          cx="8"
          cy="27"
          r="4"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></circle>
        <circle
          cx="40"
          cy="27"
          r="4"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></circle>
        <circle
          cx="24"
          cy="11"
          r="4"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></circle>
        <circle
          cx="56"
          cy="11"
          r="4"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></circle>
        <line
          x1="10.828"
          y1="24.172"
          x2="21.172"
          y2="13.828"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></line>
        <line
          x1="37.172"
          y1="24.172"
          x2="26.828"
          y2="13.828"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></line>
        <line
          x1="42.828"
          y1="24.172"
          x2="53.172"
          y2="13.828"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></line>
      </g>
    </IconWrapper>
  )
}

export default Statistics
