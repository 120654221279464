import { useRouter } from "next/router"
import { Spinner } from "components"
import styled from "styled-components"

import { URL } from "types/common"
import { ButtonType, ButtonSize, ButtonState } from "types/ui"

const ButtonWrapper = styled.button<{
  $buttonState: ButtonState
  $buttonType: ButtonType
  $buttonSize: ButtonSize
  $isLoading: boolean
  $isRounded: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.$buttonState === "inactive" ? "not-allowed" : "pointer")};
  transition: border 0.25s var(--juiced-up-ease), background 0.25s var(--juiced-up-ease),
    transform 0.25s var(--juiced-up-ease);

  // outline: none;
  will-change: transform;

  ${(props) => `
    padding: var(--button-${props.$buttonSize}-padding);
    border: solid 1px var(--button-${props.$buttonType}-border-color-${props.$buttonState});
    border-radius: ${props.$isRounded ? `var(--button-border-radius)` : `0`};
    background: var(--button-${props.$buttonType}-background-${props.$buttonState});
  `}

  ${(props) => {
    switch (props.$buttonState) {
      case "default":
        return `
          &:hover {
            border: solid 1px var(--button-${props.$buttonType}-border-color-hover);
            background: var(--button-${props.$buttonType}-background-hover);
          }
          &:active {
            transform: translateY(1px);
          }
          outline-color: var(--color-primary);
        `
      case "destructive":
        return `
          &:active {
            transform: translateY(1px);
          }
          outline-color: var(--red-destructive);
        `
      default:
        return ``
    }
  }}
`

const ButtonContent = styled.span<{
  $buttonState: ButtonState
  $buttonType: ButtonType
  $buttonSize: ButtonSize
}>`
  white-space: nowrap;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s var(--juiced-up-ease), background 0.3s var(--juiced-up-ease);

  ${(props) => `
    color: var(--button-${props.$buttonType}-font-color-${props.$buttonState});
    font-size: var(--button-${props.$buttonSize}-font-size);
  `}

  ${(props) =>
    props.$buttonState === "default" &&
    `
    &:hover {
      color: var(--button-${props.$buttonType}-font-color-hover);
    }
        
  `}
`

interface IButton {
  buttonType?: ButtonType
  buttonSize?: ButtonSize
  isDestructive?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isRounded?: boolean
  onClick?: () => void
  linkTo?: URL
  className?: string
  type?: "button" | "submit" | "reset"
}
const Button: React.FC<IButton> = ({
  buttonType = "primary",
  buttonSize = "md",
  isDestructive = false,
  isLoading = false,
  isDisabled = false,
  isRounded = true,
  onClick = () => {},
  type,
  className = "",
  linkTo,
  children,
}) => {
  const router = useRouter()

  if (linkTo) {
    onClick = () => router.push(linkTo)
  }

  const buttonState = isDisabled ? "inactive" : isDestructive ? "destructive" : "default"

  return (
    <ButtonWrapper
      $buttonState={buttonState}
      $buttonType={buttonType}
      $buttonSize={buttonSize}
      $isLoading={isLoading}
      $isRounded={isRounded}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
      className={className}
    >
      <ButtonContent
        className="button-content"
        $buttonState={buttonState}
        $buttonType={buttonType}
        $buttonSize={buttonSize}
      >
        <>{isLoading ? <Spinner size="xs" type="accent" /> : children}</>
      </ButtonContent>
    </ButtonWrapper>
  )
}

export default Button
