import React, { forwardRef, useState, useRef, FocusEvent } from "react"
import styled from "styled-components"
import { SmallRedText } from "components"
import { mergeRefs } from "utils/ui"

type TextAreaState = "error" | "focused" | "success" | "default"

const TextAreaWrapper = styled.div<{ $textAreaState?: TextAreaState; $isFocused?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const StyledLabel = styled.label`
  font-size: var(--text-area-label-text-size);
  color: var(--text-area-label-text-color);
  margin-bottom: var(--text-area-label-text-margin-bot);
  text-transform: uppercase;
  margin-left: var(--text-area-border-radius);
`
const HelperSpan = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const HelperText = styled.small<{
  $textAreaState?: TextAreaState
}>`
  flex: 1;
  font-size: var(--text-area-helper-text-size);
  margin-top: var(--text-area-helper-text-margin-top);
  color: ${(props) => `var(--text-area-helper-text-color-${props.$textAreaState})`};
  margin-left: var(--text-area-border-radius);
`
const StyledTextArea = styled.textarea<{ $textAreaState: TextAreaState; $resize?: string }>`
  resize: ${(props) => props.$resize};
  border-radius: var(--text-area-border-radius);
  padding: var(--text-area-padding);
  font-size: var(--text-area-font-size);
  color: var(--text-area-font-color);
  background: var(--text-area-background-color);
  outline: none;

  &::placeholder {
    color: var(--text-area-font-placeholder-color);
  }

  border: ${(props) => `solid 1px var(--text-area-border-color-${props.$textAreaState})`};
`

const CharacterCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
`

interface ICharacterCount {
  value?: string
  maxCharacters?: number
}
const CharacterCount: React.FC<ICharacterCount> = ({ value, maxCharacters }) => {
  if (!maxCharacters) return null

  return (
    <CharacterCountWrapper>
      <p>
        {value ? value.length : 0}/{maxCharacters} characters
      </p>
    </CharacterCountWrapper>
  )
}

interface ITextArea {
  label: string
  value: any
  name: string
  success?: boolean
  successText?: string
  helperText?: string
  includeRequiredStar?: boolean
  onChange?: (v: string) => void
  onBlur?: (e: FocusEvent) => void
  onFocus?: (e: FocusEvent) => void
  maxCharacters?: number
  providedError?: string
  required?: boolean
  resize?: string
  placeholder?: string
  className?: string
}
const TextArea = forwardRef<HTMLTextAreaElement, ITextArea>(
  (
    {
      name,
      label,
      value,
      success,
      successText,
      helperText,
      includeRequiredStar,
      onBlur = () => {},
      onFocus = () => {},
      onChange = (val) => {
        console.log(`No on change handler for ${val}`)
      },
      resize = "both",
      required,
      maxCharacters,
      placeholder,
      providedError,
      className = "",
    },
    ref
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null)
    const [focused, setFocus] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const getTextAreaState = (): TextAreaState => {
      if (error || providedError) return "error"
      if (success) return "success"
      if (focused) return "focused"
      return "default"
    }

    const textAreaState = getTextAreaState()

    const handleOnBlur = (e: FocusEvent) => {
      setFocus(false)
      onBlur(e)
    }

    const handleOnFocus = (e: FocusEvent) => {
      setFocus(true)
      onFocus(e)
    }

    const handleOnChange = (value: string) => {
      if (maxCharacters) {
        if (value.length > maxCharacters) {
          setError("Too many characters!")
        } else {
          setError(null)
        }
      }

      onChange(value)
    }

    const renderHelperText = () => {
      const helperTextContent =
        {
          error: error || providedError,
          success: successText || helperText,
          default: helperText,
        }[textAreaState] || helperText

      return (
        <HelperSpan>
          <HelperText $textAreaState={textAreaState}>{helperTextContent}</HelperText>
          <CharacterCount value={value} maxCharacters={maxCharacters} />
        </HelperSpan>
      )
    }

    return (
      <TextAreaWrapper $textAreaState={textAreaState} className={className}>
        {label.length && (
          <StyledLabel htmlFor={name}>
            {label} {includeRequiredStar ? <SmallRedText>*</SmallRedText> : null}
          </StyledLabel>
        )}
        <StyledTextArea
          onBlur={(e) => handleOnBlur(e)}
          onFocus={(e) => handleOnFocus(e)}
          ref={mergeRefs(ref, localRef)}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
          $resize={resize}
          name={name}
          required={required}
          $textAreaState={textAreaState}
        />
        {renderHelperText()}
      </TextAreaWrapper>
    )
  }
)

export default TextArea
