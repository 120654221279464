import styled from "styled-components"

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;

  & > h1 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
  }

  & > p {
    font-size: 1.2rem;
  }
`

export default function Title({ children, className = "" }) {
  return (
    <TitleWrapper className={className}>
      <>{children}</>
    </TitleWrapper>
  )
}
