import { useCallback } from "react"
import { IconFileUpload } from "components"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

type DropzoneStatus = "focused" | "error" | "accepted" | "active" | "default"

const DropzoneWrapper = styled.div<{ $dropzoneStatus?: DropzoneStatus }>`
  cursor: pointer;
  margin: 1rem;
  background: var(--dropzone-background-color);
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-md);

  ${(props) => `
    border: dashed 2px var(--dropzone-border-color-${props.$dropzoneStatus});
    ${
      ["active", "focused", "error"].includes(props.$dropzoneStatus) &&
      "background: var(--dropzone-background-color-hover);"
    }

    & p {
      color: ${
        ["active", "focused", "error"].includes(props.$dropzoneStatus)
          ? "var(--dropzone-font-color-active)"
          : "var(--dopzone-font-color)"
      };
    }
    & path {
      fill: ${
        ["active", "focused", "error"].includes(props.$dropzoneStatus)
          ? "var(--dropzone-upload-icon-color-active)"
          : "var(--dropzone-upload-icon-color)"
      };
    }

    &:hover {
      background: var(--dropzone-background-color-hover);

      & path {
        fill: var(--dropzone-upload-icon-color-active);
      }      
    }    
  `}
`
const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;

  & .instructions {
    margin-top: 1.5rem;
  }

  & .text-danger {
    color: var(--red-destructive);
    font-size: 16px;
  }
`

interface IDropzone {
  handleOnDrop: <T extends File>(acceptedFiles: T[]) => void
  maxSize?: number
  className?: string
}
const Dropzone: React.FC<IDropzone> = ({ handleOnDrop, maxSize = 1048576, className = "" }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleOnDrop(acceptedFiles)
    },
    [handleOnDrop]
  )

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
    isFileDialogActive,
    getRootProps,
    getInputProps,
    // acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: "image/jpg, image/jpeg, image/png",
    minSize: 0,
    maxSize,
  })

  const fileErrors = fileRejections?.map((f) => f.errors)?.flat()
  const fileIsTooLarge = fileErrors?.map((e) => e.code)?.includes("file-too-large")

  const getDropzoneStatus = (): DropzoneStatus => {
    if (fileErrors?.length) return "error"
    if (isDragActive || isFileDialogActive) return "active"
    if (isFocused) return "focused"
    if (isDragAccept) return "accepted"
    return "default"
  }

  return (
    <DropzoneWrapper className={className} $dropzoneStatus={getDropzoneStatus()}>
      <>
        <InputContainer {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && <IconFileUpload />}
          <p className="instructions">Drop files to upload</p>

          {isDragReject && <p>File type not accepted, sorry!</p>}
          {fileIsTooLarge && <p className="text-danger">File is too large.</p>}
        </InputContainer>
      </>
    </DropzoneWrapper>
  )
}

export default Dropzone
