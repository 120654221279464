import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Shop: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  fillDoor,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth={strokeWidth} transform="translate(0, 0)">
        <polyline
          points="20 44 20 32 28 32 28 44"
          fill={fillDoor || fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
        ></polyline>
        <polyline
          points="8 27 8 44 40 44 40 27"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
        ></polyline>
        <path
          d="M46,16a6.148,6.148,0,0,1-6.286,6,6.361,6.361,0,0,1-5.238-2.686,6.45,6.45,0,0,1-10.476,0,6.45,6.45,0,0,1-10.476,0A6.361,6.361,0,0,1,8.286,22,6.148,6.148,0,0,1,2,16L10,4H38Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
        ></path>
      </g>
    </IconWrapper>
  )
}

export default Shop
