import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSVG = styled.svg``

const Password: React.FC<IIcon> = ({
  size = 24,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  // strokeLinejoin = "miter",
  className = "",
}) => (
  <StyledSVG
    x="0px"
    y="0px"
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    className={className}
  >
    <rect
      x="3"
      y="11"
      width="18"
      height="12"
      fill={fill}
      stroke={stroke}
      strokeLinecap={strokeLinecap}
      strokeMiterlimit={strokeMiterlimit}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="12"
      cy="17"
      r="2"
      fill={fill}
      stroke={stroke}
      strokeLinecap={strokeLinecap}
      strokeMiterlimit={strokeMiterlimit}
      strokeWidth={strokeWidth}
    />
    <path
      d="M17,11V6a4.951,4.951,0,0,0-4.9-5H12A4.951,4.951,0,0,0,7,5.9V11"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit={strokeMiterlimit}
      strokeWidth={strokeWidth}
    />
  </StyledSVG>
)

export default Password
