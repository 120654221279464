import * as R from "ramda"
import styled from "styled-components"
import { AvatarSize } from "types/ui"
import { noop } from "utils/misc"
import media from "utils/mediaQueryTemplates"

const AvatarWrapper = styled.div<{
  $avatarSize: AvatarSize
  $round?: boolean
  $shrink?: boolean
  $hasImage?: boolean
  $hasClick?: boolean
  $noBackground?: boolean
}>`
  --avatar-size-xs: 32px;
  --avatar-size-sm: 48px;
  --avatar-size-md: 72px;
  --avatar-size-lg: 96px;
  --avatar-size-xl: 160px;

  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${(props) => (props.$hasClick ? "pointer" : "initial")};

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: ${(props) => (props.$round ? "50%" : "0.25rem")};
  }

  --size: var(--avatar-size-${(props) => props.$avatarSize});
  ${(props) => media.mobileOnly`
    ${
      props.$shrink
        ? `
      --size: var(--avatar-size-sm);
    `
        : `
    `
    }
  `}

  height: var(--size);
  width: var(--size);
  font-size: calc(var(--size) * 0.4);
`

const extractInitialsFromName = (name: string) => {
  if (!name) return "AN"
  if (name.split(" ").length > 1) {
    return name.split(" ")[0][0].toUpperCase() + name.split(" ").pop()[0].toUpperCase()
  }

  return name[0].toUpperCase() + name[1].toUpperCase()
}

interface IAvatar {
  name: string
  image?: React.ReactNode
  avatarSize?: AvatarSize
  round?: boolean
  shrink?: boolean
  noBackground?: boolean
  onClick?: () => void
  className?: string
}
const Avatar: React.FC<IAvatar> = ({
  name,
  image,
  noBackground = false,
  avatarSize = "md",
  shrink = true,
  round = true,
  onClick = null,
  className = "",
}) => {
  return (
    <AvatarWrapper
      $noBackground={noBackground}
      $avatarSize={avatarSize}
      $round={round}
      $shrink={shrink}
      $hasImage={Boolean(image)}
      $hasClick={!R.isNil(onClick)}
      onClick={onClick || noop}
      className={className}
    >
      {image ? image : <>{extractInitialsFromName(name)}</>}
    </AvatarWrapper>
  )
}

export default Avatar
