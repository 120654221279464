import { useMemo } from "react"
import { useDispatch, shallowEqual } from "react-redux"
import { useTypedSelector } from "models/redux"
import { Actions as authActions } from "models/auth"
import { useAuthedUser } from "hooks/auth/useAuthedUser"

export const useAuthedUserProfile = () => {
  const dispatch = useDispatch()
  const [user, isSessionLoading] = useAuthedUser()

  const authIsLoading = useTypedSelector((state) => state.auth.isLoading)
  const authIsUpdating = useTypedSelector((state) => state.auth.isUpdating)
  const authIsRefreshing = useTypedSelector((state) => state.auth.isUpdating)
  const authMessage = useTypedSelector((state) => state.auth.message, shallowEqual)
  const authError = useTypedSelector((state) => state.auth.error, shallowEqual)
  const authMeta = useTypedSelector((state) => state.auth.authActionNameToMetaLookup, shallowEqual)

  const AuthActions = useMemo(() => {
    const resendConfirmAccountEmail = () => dispatch(authActions.resendConfirmAccountEmail())
    const confirmAccount = (token: string) => dispatch(authActions.confirmAccount({ token }))
    const sendPasswordResetEmail = (email: string) => dispatch(authActions.sendPasswordResetEmail({ email }))
    const sendPasswordChangeRequest = ({ password, token }) =>
      dispatch(authActions.sendPasswordChangeRequest({ password, token }))
    const updateEmailAndUsername = ({ email, username }) =>
      dispatch(authActions.updateEmailAndUsername({ email, username }))

    return {
      resendConfirmAccountEmail,
      confirmAccount,
      sendPasswordResetEmail,
      sendPasswordChangeRequest,
      updateEmailAndUsername,
    }
  }, [])

  const provider = user?.provider

  const userProfile = useMemo(() => {
    const profile: Record<string, any> = user?.profile
      ? Object.keys(user.profile)
          .filter((k) => k !== "velupAccessToken")
          .reduce((acc, k) => {
            acc[k] = user.profile[k]
            return acc
          }, {})
      : {}

    profile.provider = user?.provider

    // if (provider === "linkedin") {
    //   // return { ...user?.userInfo?.user?.profile, provider: "linkedin" }
    //   return { ...user.profile, provider }
    // }

    // if (provider === "twitter") {
    //   // return { ...user?.userInfo?.user?.profile, provider: "twitter" }
    //   return { ...user.profile, provider }
    // }

    return profile
  }, [user, provider])

  return {
    userProfile,
    authIsLoading,
    authIsUpdating,
    authIsRefreshing,
    authMessage,
    authError,
    isSessionLoading,
    authMeta,
    AuthActions,
  }
}
