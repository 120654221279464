import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Share: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 1,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <g strokeWidth="1" transform="translate(0.5, 0.5)">
        <polyline
          points="7 31 7 45 45 45 45 13 39 13"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></polyline>
        <path
          d="M3,18,19,4v8c10.417,0,18,3.833,18,20-3.75-4.917-7.333-8.083-18-8v8Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap={strokeLinecap}
          strokeMiterlimit={strokeMiterlimit}
          strokeWidth={strokeWidth}
          strokeLinejoin={strokeLinejoin}
        ></path>
      </g>
    </IconWrapper>
  )
}

export default Share
