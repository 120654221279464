import styled from "styled-components"
import { SpacerSize } from "types/ui"

const StyledSpacer = styled.div<{ $size: SpacerSize; $fullWidth?: boolean; $fullHeight?: boolean }>`
  ${(props) => `
    width: ${props.$fullWidth ? "100%" : `var(--spacing-${props.$size})`};
    height: ${props.$fullHeight ? "100%" : `var(--spacing-${props.$size})`};
  `}
`

interface ISpacer {
  size?: SpacerSize
  fullWidth?: boolean
}
const Spacer: React.FC<ISpacer> = ({ size = "xs", fullWidth = false }) => (
  <>
    <StyledSpacer $size={size} $fullWidth={fullWidth} />
  </>
)

export default Spacer
