import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSVG = styled.svg``

const MapPositionPin: React.FC<IIcon> = ({
  size = 32,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  className = "",
}) => {
  return (
    <StyledSVG
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <path
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        d="M26,10.545 c0,5.891-10,16.418-10,16.418S6,16.437,6,10.545C6,4.505,11.168,1,16,1S26,4.505,26,10.545z"
        strokeLinejoin={strokeLinejoin}
      ></path>{" "}
      <circle
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        cx="16"
        cy="11"
        r="3"
        strokeLinejoin={strokeLinejoin}
      ></circle>{" "}
      <path
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        d="M8,23.717 C4.373,24.44,2,25.641,2,27c0,2.209,6.268,4,14,4s14-1.791,14-4c0-1.359-2.373-2.56-6-3.283"
        strokeLinejoin={strokeLinejoin}
      ></path>
    </StyledSVG>
  )
}

export default MapPositionPin
