/**
 * Used to take multiple refs and apply them to one element
 */
type PotentialRefType<T> = React.RefCallback<T> | React.MutableRefObject<T | null>
export const mergeRefs = <T extends Element>(...refs: Array<PotentialRefType<T>>) => {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null
  if (filteredRefs.length === 0) return filteredRefs[0]

  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}
