import React from "react"
import styled from "styled-components"
import { IIcon } from "types/ui"
import { noop } from "utils/misc"

const StyledSVG = styled.svg`
  cursor: pointer;
`

const Close: React.FC<IIcon> = ({
  fill = "none",
  stroke = "#605DEC",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeLinejoin = "miter",
  strokeMiterlimit = 10,
  className = "",
  onClick = noop,
}) => (
  <StyledSVG onClick={onClick} className={className} x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64">
    <g strokeWidth={strokeWidth} transform="translate(0, 0)">
      <line
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
        x1="44"
        y1="20"
        x2="20"
        y2="44"
      ></line>
      <line
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        strokeMiterlimit={strokeMiterlimit}
        x1="44"
        y1="44"
        x2="20"
        y2="20"
      ></line>
    </g>
  </StyledSVG>
)

export default Close
