import styled from "styled-components"

const StyledSvg = styled.svg``

interface IHamburgerMenu {
  stroke?: string
  strokeWidth?: number
  strokeLinecap?: "round" | "butt" | "square" | "inherit"
  strokeLinejoin?: "round" | "inherit" | "miter" | "bevel"
}
const HamburgerMenu: React.FC<IHamburgerMenu> = ({
  stroke = "#605DEC",
  strokeWidth = 3,
  strokeLinecap = "round",
  strokeLinejoin = "round",
}) => (
  <StyledSvg width="48" height="49" viewBox="0 0 48 49" fill="none">
    <path
      d="M39 15.5H9"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
    />
    <path
      d="M33 24.5H9"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
    />
    <path
      d="M27 33.5H9"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
    />
  </StyledSvg>
)

export default HamburgerMenu
