import { Card, Spacer, SmallRedText } from "components"
import styled from "styled-components"

const ErrorTileWrapper = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin: 1rem auto;

  font-size: 20px;
`

export default function ErrorTile({ error }) {
  const message = error?.message || error

  return (
    <ErrorTileWrapper>
      <Spacer size="lg" />
      <h2>Error</h2>
      <Spacer size="lg" />
      <SmallRedText>{String(message)}</SmallRedText>
      <Spacer size="lg" />
    </ErrorTileWrapper>
  )
}
