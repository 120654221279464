import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import { Button } from "components"
// import { noop } from "utils/misc"

import styled from "styled-components"
import "pure-react-carousel/dist/react-carousel.es.css"

const PureReactCarouselWrapper = styled.div`
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const StyledCarouselSlider = styled(Slider)`
  min-height: 420px;
`
const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`
const StyledBackButton = styled(ButtonBack)`
  padding: 0;
  color: inherit;
  border: none;
  background: transparent;
`
const StyledNextButton = styled(ButtonNext)`
  padding: 0;
  color: inherit;
  border: none;
  background: transparent;
`

interface IPureReactCarousel {
  readonly orientation?: "horizontal" | "vertical"
  readonly naturalSlideWidth?: number
  readonly naturalSlideHeight?: number
  readonly visibleSlides?: number
  readonly items: React.ReactNode[]
  readonly title?: React.ReactNode
  readonly infinite?: boolean
  readonly className?: string
}
export default function PureReactCarousel({
  orientation = "horizontal",
  naturalSlideWidth = 300,
  naturalSlideHeight = 400,
  visibleSlides = 1,
  items = [],
  title,
  infinite = true,
  className = "",
}: IPureReactCarousel) {
  return (
    <PureReactCarouselWrapper className={className}>
      <CarouselProvider
        orientation={orientation}
        naturalSlideWidth={naturalSlideWidth}
        naturalSlideHeight={naturalSlideHeight}
        totalSlides={items.length}
        visibleSlides={visibleSlides}
        infinite={infinite}
        isIntrinsicHeight
        // hasMasterSpinner
      >
        <ButtonsRow>
          <StyledBackButton>
            <Button buttonType="primary">{`<`}</Button>
          </StyledBackButton>

          {title ? title : null}

          <StyledNextButton>
            <Button buttonType="primary">{`>`}</Button>
          </StyledNextButton>
        </ButtonsRow>

        <StyledCarouselSlider>
          {items.map((item, idx) => (
            <Slide key={idx} index={idx}>
              {item}
            </Slide>
          ))}
        </StyledCarouselSlider>
      </CarouselProvider>
    </PureReactCarouselWrapper>
  )
}
