import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Park: React.FC<IIcon> = ({
  size = 48,
  fill = "none",
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <line
        x1="2"
        y1="40"
        x2="28"
        y2="40"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="6"
        y1="40"
        x2="6"
        y2="46"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="24"
        y1="40"
        x2="24"
        y2="46"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <line
        x1="38"
        y1="46"
        x2="38"
        y2="22"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></line>
      <rect
        x="6"
        y="26"
        width="18"
        height="8"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></rect>
      <path
        d="M43.046,14.3A9.948,9.948,0,0,0,44,10c0-4.418-2.686-8-6-8s-6,3.582-6,8a9.948,9.948,0,0,0,.954,4.3A11.02,11.02,0,0,0,30,22c0,5.523,3.582,10,8,10s8-4.477,8-10A11.02,11.02,0,0,0,43.046,14.3Z"
        fill={fill}
        stroke={stroke}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
        strokeWidth={strokeWidth}
      ></path>
    </IconWrapper>
  )
}

export default Park
