import IconWrapper from "./IconWrapper"
import { IIcon } from "types/ui"

const Clock: React.FC<IIcon> = ({
  size = 32,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  strokeLinejoin = "miter",
  ...props
}) => {
  return (
    <IconWrapper size={size} {...props}>
      <circle
        cx="16"
        cy="16"
        r="15"
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
      <polyline
        points="16 7 16 16 25 16"
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
    </IconWrapper>
  )
}

export default Clock
