import styled from "styled-components"
import { logoSizeToSrcMapping } from "constants/ui"
import { noop } from "utils/misc"

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: var(--pure-white);
  background: rgba(255, 255, 255, 0.98);
  padding: 1rem;
  border-radius: var(--border-radius-sm);
`
const StyledLogo = styled.img`
  // height: 75px;
  // width: 215px;
  cursor: pointer;
`

interface IVelupLogo {
  logoSize: keyof typeof logoSizeToSrcMapping
  className?: string
  onClick?: () => void
}
const VelupLogo: React.FC<IVelupLogo> = ({ logoSize, onClick = noop, className = "" }) => {
  const imageSrc = logoSizeToSrcMapping[logoSize]

  return (
    <LogoContainer className={className}>
      <StyledLogo src={imageSrc} alt="velup logo" onClick={onClick} />
    </LogoContainer>
  )
}

export default VelupLogo
