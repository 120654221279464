import { Fragment } from "react"
import Link from "next/link"
import { Column, IconFacebook, IconLinkedin, IconTwitter, Row, Spacer } from "components"
import { QuickLink } from "types/ui"
import media from "utils/mediaQueryTemplates"

import styled from "styled-components"

import { formatFullYear } from "utils/dates"

const footerLinks = {
  About: [
    { label: "About Velup", path: "/about/" },
    { label: "How it works", path: "/how-it-works/" },
    { label: "The Team", path: "/the-team/" },
    { label: "Partnerships", path: "/contact-us/" },
    { label: "Blog", path: "/blog/" },
  ],
  Explore: [
    // { label: "Rate professional development", path: "/events/" },
    { label: "Rate PD", path: "/events/" },
    { label: "Find providers", path: "/search/" },
    { label: "View evaluations", path: "/reviews/" },
    { label: "Connect with presenters", path: "/presenters/" },
    { label: "Search for events", path: "/search/" },
    { label: "Develop PD plans", path: "/plans/" },
    { label: "Manage profile", path: "/profile/" },
  ],
  "For Providers": [
    { label: "Claim ownership", path: "/providers/claim/" },
    { label: "Manage provider", path: "/providers/manage/" },
    { label: "Create an event", path: "/providers/manage/events/create/" },
    { label: "Analyze results", path: "/providers/manage/analyze/" },
    { label: "Source presenters", path: "/presenters/search/" },
  ],
  Support: [
    { label: "Help Center", path: "/help-center/" },
    { label: "Contact us", path: "/contact/" },
    { label: "Privacy policy", path: "/terms-of-service/" },
    { label: "Terms of service", path: "/terms-of-service/" },
    { label: "Trust and safety", path: "/trust-and-safety/" },
  ],
}

const FooterWrapper = styled.div`
  // height: 450px;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: var(--footer-z-index);
  background: var(--footer-background);
  border-right: solid 1px var(--footer-separator-color);
  border-left: solid 1px var(--footer-separator-color);
  border-bottom: solid 1px var(--footer-separator-color);
`
const FooterTop = styled.div`
  flex: 1 1 auto;
  display: flex;
  border-top: 1px solid var(--footer-separator-color);
  padding: var(--page-side-padding);
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;  
  `}
`
const FooterLogo = styled.div`
  flex: 1;
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;

  & img {
    margin: 0 auto;
  }
`
const FullWidthRow = styled((props) => <div {...props} />)`
  flex: 3;
  width: 100%;
  // display: flex;
  // justify-content: space-around;
  // flex-direction: column;
  // align-items: flex-start;
  // flex-wrap: no-wrap;

  ${media.small`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  `}

  ${media.medium`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;    
  `}
`
const FooterColumn = styled(Column)`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex: 1;
  margin-bottom: 2rem;

  ${media.small`
    align-items: flex-start;
  `}
`
const ColumnTitle = styled.p`
  font-weight: bold;
  color: var(--footer-column-header-text-color);
  margin-bottom: 1rem;
  font-size: var(--footer-column-header-font-size);
`
const ColumnItem = styled.p`
  margin-bottom: 1rem;

  & a {
    color: var(--footer-column-text-color);
    font-size: var(--footer-column-text-font-size);
    cursor: pointer;
    font-weight: 400;
  }

  & a:hover {
    color: var(--footer-column-header-text-color);
  }
`
const FooterBottom = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--footer-separator-color);
  padding: 0 var(--page-side-padding);

  & .icons {
    display: flex;
    align-items: center;

    & svg {
      height: 25px;
      width: 25px;

      & path {
        fill: var(--footer-column-header-text-color);
      }
    }
  }

  & p {
    margin: 0;
    font-size: var(--footer-column-text-font-size);
    color: var(--footer-column-header-text-color);
  }
`
const StyledLogo = styled.img`
  height: 50px;
  width: 150px;
`
// const StyledColumn = styled((props) => <Column {...props} />)`
//   align-items: center;
//   flex: 1;
// `

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <FooterTop>
        <FooterLogo>
          <StyledLogo src={"/assets/logos/logo_150x50.svg"} alt="velup logo" />
        </FooterLogo>
        <FullWidthRow>
          {Object.keys(footerLinks).map((sectionTitle) => {
            const sectionLinks = footerLinks[sectionTitle]

            return (
              <Fragment key={sectionTitle}>
                <FooterColumn>
                  <ColumnTitle>{sectionTitle}</ColumnTitle>
                  {sectionLinks.map((link: QuickLink) => (
                    <ColumnItem key={link.label}>
                      <Link href={link.path}>{link.label}</Link>
                    </ColumnItem>
                  ))}
                </FooterColumn>
              </Fragment>
            )
          })}
        </FullWidthRow>
      </FooterTop>
      <FooterBottom>
        <div className="icons">
          <IconFacebook />
          <Spacer size="md" />
          <IconLinkedin />
          <Spacer size="md" />
          <IconTwitter />
        </div>
        <p>&copy; {formatFullYear(new Date())} Velup LLC</p>
      </FooterBottom>
    </FooterWrapper>
  )
}

export default Footer
