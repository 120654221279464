import { useState } from "react"
import { motion } from "framer-motion"
import styled, { CSSObject } from "styled-components"

const AccordianWrapper = styled.ul``
const AccordianItemWrapper = styled.li<{ $elevation?: number }>`
  background: #fff;
  ${(props) => Boolean(props.$elevation) && `box-shadow: var(--elevation-${props.$elevation});`}
  border-radius: 0.2rem;
  margin-bottom: 20px;

  & > p {
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 1rem;
    cursor: pointer;
    font-family: var(--font-heading);
    font-size: 1.5rem;
  }

  & section {
    transform-origin: top center;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;

    & > span.content {
      display: block;
      margin-bottom: 20px;
    }
  }
`

const accordianVariants = {
  open: {
    // transition: {
    //   type: "spring",
    //   delay: 0,
    //   stiffness: 250,
    //   damping: 48,
    //   mass: 3,
    //   duration: 0.5,
    // },
    height: "100%",
  },
  closed: {
    // transition: {
    //   type: "tween",
    //   duration: 0.3,
    // },
    height: "0px",
  },
}

interface AccordianItemProps {
  id: string
  title: string
  renderTitle: (title: string, isExpanded: boolean, toggleExpanded: () => void) => React.ReactNode
  isExpanded: boolean
  setExpanded: (p: boolean | string) => void
  elevation?: number
  contentHeight?: number
  itemWrapperStyles?: CSSObject
  shouldToggleOpen?: boolean
  shouldHandleToggle?: boolean
  children: React.ReactNode
}
const AccordianItem: React.FC<AccordianItemProps> = ({
  id,
  title,
  renderTitle,
  isExpanded = false,
  setExpanded,
  elevation,
  contentHeight = 500,
  itemWrapperStyles,
  shouldToggleOpen,
  shouldHandleToggle,
  children,
  ...props
}) => {
  const toggleExpanded = () => {
    setExpanded(isExpanded ? false : id)
  }

  return (
    <AccordianItemWrapper
      key={id}
      $elevation={elevation}
      onClick={() => (shouldHandleToggle ? (shouldToggleOpen ? toggleExpanded() : setExpanded(id)) : null)}
      style={itemWrapperStyles}
      {...props}
    >
      {renderTitle(title, isExpanded, toggleExpanded)}
      <motion.section
        layoutId={id}
        key={id}
        initial={"closed"}
        animate={isExpanded ? "open" : "closed"}
        variants={accordianVariants}
      >
        <span className="content">{children}</span>
      </motion.section>
    </AccordianItemWrapper>
  )
}

export default function Accordian({
  items,
  renderTitle = (title: string, expanded: boolean = false, toggle = () => {}) => <p>{title}</p>,
  elevation = 1,
  itemWrapperStyles = {},
  shouldToggleOpen = true,
  shouldHandleToggle = true,
  ...props
}) {
  const [expanded, setExpanded] = useState<boolean | string>(false)

  return (
    <AccordianWrapper {...props}>
      {items.map((item: any, i: string) => (
        <AccordianItem
          key={i}
          id={i}
          isExpanded={expanded === i}
          setExpanded={setExpanded}
          title={item.title}
          contentHeight={item.contentHeight}
          renderTitle={renderTitle}
          elevation={elevation}
          itemWrapperStyles={itemWrapperStyles}
          shouldToggleOpen={shouldToggleOpen}
          shouldHandleToggle={shouldHandleToggle}
        >
          {item.contents}
        </AccordianItem>
      ))}
    </AccordianWrapper>
  )
}
