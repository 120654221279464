import { useRef, useCallback, forwardRef } from "react"
import styled from "styled-components"
// import { useWindowScrollPosition } from "hooks/ui/useWindowScrollPosition"
import { useNavbar } from "hooks/ui/useNavbar"
import {
  Card,
  // IconHamburgerMenu,
  NavbarDropdown,
  NavbarLogo,
  NavbarLinks,
} from "components"
import media from "utils/mediaQueryTemplates"

const NavbarCardWrapper = styled(
  forwardRef<HTMLDivElement, any>((props, ref) => <Card ref={ref} {...props} />)
)`
  height: var(--navbar-height);
  background: var(--navbar-background);
  position: sticky;
  top: 0;
  padding-left: 10px;
  padding-right: 10px;
  z-index: var(--z-index-navbar);
  background: var(--pure-white);
  transition: box-shadow 0.2s var(--juiced-up-ease);

  width: 100%;
  max-width: 100vw;

  display: flex;
  flex-direction: row;

  ${media.small`
    padding-left: var(--navbar-padding-left);
    padding-right: var(--navbar-padding-right);  
  `}
`
const NavbarContent = styled.div`
  flex: 1;
  position: relative;
  z-index: var(--z-index-navbar);
  height: var(--navbar-height);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Navbar: React.FC = () => {
  const navbarRef = useRef(null)
  const { yPos, menuOpen, handleDropdownLabelClick, closeDropdownMenu, navigateInternal } = useNavbar()

  const refCallback = useCallback((label, node) => {
    if (node !== null) {
      navbarRef.current = node
    }
  }, [])

  return (
    <>
      <NavbarCardWrapper ref={(node) => refCallback("navbar", node)} noPadding elevation={yPos > 0 ? "high" : "flat"}>
        <NavbarContent>
          <NavbarLogo />

          <NavbarLinks toggleMenu={handleDropdownLabelClick} />
        </NavbarContent>
      </NavbarCardWrapper>
      <NavbarDropdown
        dropdownMenuOpen={menuOpen}
        navigateInternal={navigateInternal}
        closeDropdownMenu={closeDropdownMenu}
        navbarRef={navbarRef}
      />
    </>
  )
}

export default Navbar
