import Link from "next/link"
import styled from "styled-components"

type LinkType = "primary" | "secondary" | "accent"

const NextLinkWrapper = styled.span<{ $type: LinkType }>`
  cursor: pointer;
  color: ${(props) => `var(--color-${props.$type})`};
`

interface INextLink {
  to: string
  type?: LinkType
  className?: string
}
const NextLink: React.FC<INextLink> = ({ to, type = "primary", children, className = "" }) => {
  return (
    <Link href={to}>
      <NextLinkWrapper className={className} $type={type}>
        <>{children}</>
      </NextLinkWrapper>
    </Link>
  )
}

export default NextLink
