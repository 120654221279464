import styled from "styled-components"
import { IIcon } from "types/ui"

const StyledSVG = styled.svg``

export default function Warning({
  size = 48,
  stroke = "#444444",
  strokeWidth = 2,
  strokeLinecap = "square",
  strokeMiterlimit = 10,
  // strokeLinejoin = "miter",
  className = "",
}: IIcon) {
  return (
    <StyledSVG
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <circle data-color="color-2" data-stroke="none" cx="24" cy="36" r="1" fill="#444" />
      <polygon
        points="3 43 24 4 45 43 3 43"
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
      <line
        x1="24"
        y1="18"
        x2="24"
        y2="29"
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
      <circle
        cx="24"
        cy="36"
        r="1"
        fill="none"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeMiterlimit={strokeMiterlimit}
      />
    </StyledSVG>
  )
}
